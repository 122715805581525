import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLayout } from '../../../../component/layouts/reducerSlices/layoutSlice';
import { fetchStatus } from '../../../../api/client';
import Statement from '../../../../component/Controls/custom/textbox/statement';
import { isFormValid } from '../../../../component/Controls/services/formService';
import { getSettingICP, selectModificationStatus, selectSettingICP, selectSettingICPStatus, updateModificationStatus, updateSettingICP, updateSingleData } from '../../reducerSlices/settingICPSlice';
import ImageLoader from '../../../../component/Controls/loaders/imageLoader';


function SettingICP() {

    const dispatch = useDispatch();
    const ICP_FORM = "ICPForm";

    useEffect(() => {
        dispatch(updateLayout({
            page: 2,
            title: "ICP"
        }));
    }, []);

    const [iCPFSubmitted, setICPFSubmitted] = useState(false);
    const [iCPDescription, setICPDescription] = useState("");

    const settingICPRequestStatus = useSelector(selectSettingICPStatus);
    const settingICP = useSelector(selectSettingICP);
    const settingICPModificationStatus = useSelector(selectModificationStatus);

    const _loadSettingICP = async () => {
        await dispatch(getSettingICP({}));
    }

    useEffect(() => {
        if (settingICPRequestStatus === fetchStatus.IDLE) {
            _loadSettingICP();
        }
    }, [settingICPRequestStatus]);

    useEffect(() => {
        setICPDescription(settingICP && settingICP.icpDescription)
    }, settingICP)

    useEffect(() => {
        if (settingICPModificationStatus === fetchStatus.SUCCEEDED) {
            dispatch(updateSingleData({ icpDescription: iCPDescription }))
            dispatch(updateModificationStatus())
        }
    }, [settingICPModificationStatus])

    const onSaveClicked = async (e, id) => {
        e.preventDefault();

        if (isFormValid(ICP_FORM)) {
            setICPFSubmitted(false);

            dispatch(updateSettingICP({
                id: id,
                data: JSON.stringify({
                    icpDescription: iCPDescription
                })
            }));
        } else {
            setICPFSubmitted(true);
        }
    }

    return (
        <>
            {(settingICPRequestStatus === fetchStatus.LOADING ||
                settingICPModificationStatus === fetchStatus.LOADING
            ) && <div className="app-container-loader">
                    <ImageLoader />
                </div>}

            <div className="row justify-content-between mb-4">
                <div className="col-md-6">
                    <span className="detail-container-title">ICP </span><span className="detail-container-light-title">(Ideal Customer Profile)</span>
                </div>
                <div className="col-auto">
                    <button
                        className="btn btn-primary"
                        onClick={(e) => onSaveClicked(e, settingICP ? settingICP.id : "")}
                    >
                        Save
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {/* <label for="exampleFormControlTextarea1" className="form-label">ICP Description</label> */}
                    <label id="lblICP" className="field-label pt-2" htmlFor="txtICP">ICP Description</label>
                    <Statement
                        id="txtICPDescription"
                        name="txtICPDescription"
                        value={iCPDescription}
                        StatementClasses="form-control icp-description"
                        onChange={(e) => setICPDescription(e.target.value)}
                        required={true}
                        errorMessage="Please enter ICP description"
                        form={ICP_FORM}
                        submited={iCPFSubmitted}
                        placeholder="Enter ICP description..." />
                </div>
            </div>
        </>
    )
}

export default SettingICP