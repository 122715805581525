import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, selectModalPopUpDetail } from './reducerSlices/hubspotMeetingModalPopUpSlice';
import meteorRainImg from './../../../assets/images/clipart/meteor-rain.gif';


function HubspotMeetingModalPopUp(props) {

    const dispatch = useDispatch();
    const modalPopUpDetails = useSelector(selectModalPopUpDetail);

    const onCloseClicked = () => {
        dispatch(closeModal());
    };

    return (
        <>
            {(modalPopUpDetails && modalPopUpDetails.show) ? (<>
                <div className="modal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl mb-0" role="document">
                        <div className="modal-content">
                            <div className="modal-header d-block">
                                <div className="d-flex">
                                    <h5 className="modal-title">Schedule a meeting</h5>
                                    <button
                                        type="button"
                                        className="modal-close-button ms-auto"
                                        onClick={onCloseClicked}>
                                        <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                                    </button>
                                </div>
                                <div className="d-flex">
                                    <span className="modal-sub-title">Use the calendar below to book your meeting</span>
                                </div>
                            </div>
                            <div className="modal-body hubspot-meeting-modal-body">
                                <div className="row">
                                    {/* <div className="col-md-4 d-flex justify-content-center align-items-center">
                                        <div className="row p-2">
                                            <div className="col-md-12 justify-content-center">
                                                <center>
                                                    <img src={meteorRainImg} alt="no photo" style={{ width: "20%" }} />
                                                    <div className="animate__animated animate__zoomIn">
                                                        <h3 className="pt-5">
                                                            Congratulations</h3>
                                                    </div>
                                                    <div className="animate__animated animate__headShake">
                                                        <p>Now you are part of FollowUps. We appreciate your involvement and will get back to you shortly.</p>
                                                    </div>
                                                </center>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-md-12">
                                        <iframe src="https://meetings.hubspot.com/doyle-slayton2" className='iframe-hubspot-meeting'></iframe>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="modal-footer">
                                <CancelButton
                                    CancelButtonClasses="mr-2"
                                    onCancelClicked={() => onCloseClicked()} />
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="modal-backdrop fade show">
                </div>
            </>) : <></>}
        </>
    );
}

export default HubspotMeetingModalPopUp;