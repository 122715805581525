import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginError, selectLoginStatus, selectSocialLoginError, selectSocialLoginStatus, socialLogin, updateLoginStatus, updateProfileStatus, updateSocialLoginStatus } from '../reducerSlices/accountsSlice';
import { isFormValid } from '../../../component/Controls/services/formService';
import { accountService } from '../services/accountService';
import { fetchStatus, linkedInOptions, redirectHubSpotMarketPlace, tenantId } from '../../../api/client';
import EmailText from '../../../component/Controls/custom/textbox/emailText';
import PasswordText from '../../../component/Controls/custom/textbox/passwordText';
import Logo from '../../../component/logo/logo';
import loginPageImg from '../../../assets/images/login-page.png';
import SocialLoginButton from '../../../component/Controls/custom/buttons/socialLoginButton';
import goolgeLogo from '../../../assets/images/google.png';
import linkedinLogo from '../../../assets/images/linkedin.png';
import getfollowups from '../../../assets/logo/followups-logo-light.png';
import facebookImg from "../../../assets/images/clipart/facebook.svg";
import facebookImg2 from "../../../assets/images/clipart/facebook-2.svg";
import linkedinImg from "../../../assets/images/clipart/linkedin.svg";
import linkedinImg2 from "../../../assets/images/clipart/linkedin-2.svg";
import twitterImg from "../../../assets/images/clipart/twitter.svg";
import twitterImg2 from "../../../assets/images/clipart/twitter-2.svg";

import { useGoogleLogin } from '@react-oauth/google';
import { LinkedIn, useLinkedIn } from 'react-linkedin-login-oauth2';
import '../../../assets/css/register.css';
import followupsDarkLogo from '../../../assets/logo/followups-logo-dark.png';
import followupsWhiteLogo from '../../../assets/logo/followups-logo-white.png';
import ImageLoader from '../../../component/Controls/loaders/imageLoader';

function Login() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const LOGIN_FORM = "LoginForm";

    const loginButtonRef = useRef(null);

    const loginStatus = useSelector(selectLoginStatus);
    const socialLoginStatus = useSelector(selectSocialLoginStatus);
    const socialLoginError = useSelector(selectSocialLoginError);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [lfSubmitted, setLFSubmitted] = useState(false);

    const [twitterImage, setTwitterImage] = useState(twitterImg);
    const [linkedinImage, setLinkedinImage] = useState(linkedinImg);
    const [facebookImage, setFacebookImage] = useState(facebookImg);


    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const onLoginClicked = (e) => {
        e.preventDefault();
        // if (isFormValid(LOGIN_FORM)) {
        if (email !== "" && password !== "") {
            accountService.login({ client: tenantId, email: email, password: password, authenticationType: "TOKEN" });
        }
        else {
            setLFSubmitted(true);
        }
    }

    useEffect(() => {
        if (loginStatus === fetchStatus.SUCCEEDED) {
            if (accountService.isAuthenticated()) {
                dispatch(updateLoginStatus());

                if (accountService.isAdmin()) {
                    // alert("admin...")
                    navigate("/portal-selection");
                }
                else if (accountService.isCompanySetup() === false) {
                    redirectHubSpotMarketPlace();
                } else {
                    navigate("/dashboard");
                }

            }
        }
    }, [loginStatus]);

    useEffect(() => {
        if (loginStatus === fetchStatus.FAILED) {
            // alert("Invalid login attempt")
        }
    }, [loginStatus]);


    //Socal Login
    const _getSocialLogin = async (token, provider) => {
        let dataObj = {
            token: token,
            provider: provider,
        }
        await dispatch(socialLogin(dataObj));
    };

    useEffect(() => {
        if (socialLoginStatus === fetchStatus.SUCCEEDED) {
            if (accountService.isAuthenticated()) {
                dispatch(updateSocialLoginStatus());
                dispatch(updateProfileStatus());
                if (accountService.isRegistrationComplete() === false) {
                    navigate("/profile-details");
                }
                else if (accountService.isCompanySetup() === false) {
                    redirectHubSpotMarketPlace();
                } else {
                    navigate("/dashboard");
                }
            }
        }
    }, [socialLoginStatus]);

    useEffect(() => {
        if (socialLoginStatus === fetchStatus.FAILED) {
            if (socialLoginError === "Email address is not verified") {
                // navigate("/submission");
                alert("Email address is not verified");
            }
        }
    }, [socialLoginStatus]);

    const signInWithGoogleClicked = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            try {
                if (codeResponse && codeResponse.access_token) {
                    _getSocialLogin(codeResponse.access_token, "google");
                }
            } catch (error) {
            }
        },
        onError: (error) => console.error("Google Login Failed:", error),
    });

    const redirectUrl = linkedInOptions.REDIRECT_URL;
    const linkedInClientId = linkedInOptions.CLIENT_ID;
    const scope = "openid profile email";

    const { linkedInLogin } = useLinkedIn({
        clientId: linkedInClientId,
        scope: scope,
        redirectUri: redirectUrl,
        onSuccess: (code) => {

            try {
                if (code) {
                    _getSocialLogin(code, "linkedin");
                }
            } catch (error) {
                console.error("Error during LinkedIn login:", error);
            }

        },
        onError: (error) => {
        }
    });


    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (loginButtonRef.current) {
                loginButtonRef.current.click();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('keypress', handleKeyPress);

        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
    }, []);


    return (
        <>
            {(loginStatus === fetchStatus.LOADING ||
                socialLoginStatus === fetchStatus.LOADING
            ) && <div className="app-container-loader">
                    <ImageLoader />
                </div>
            }

            <div id="page" className="page font--jakarta">
                <div className="login bg--scroll login-section division">
                    <div className="container">
                        <div className="row justify-content-center">

                            <div className="col-lg-11">
                                <div className="register-page-wrapper r-16 bg--fixed">
                                    <div className="row">
                                        <div className="col-md-6 register-page-img">
                                            <div className="register-page-txt color--white">
                                                <img className="img-fluid" src={followupsWhiteLogo} alt="logo-image"></img>
                                                <h2 className="s-48 w-700">Welcome</h2>
                                                <h2 className="s-48 w-700">back to FollowUps</h2>
                                                <p className="p-md mt-25">
                                                    <ul>
                                                        <li>FollowUps helps organize all of your leads.</li>
                                                        <li>Know exactly what to do next with every lead.</li>
                                                        <li>Improve the quality of your prospecting data.</li>
                                                    </ul>
                                                </p>
                                                <div className="register-page-copyright">
                                                    <p className="p-sm">© 2025 FollowUps. <span>All Rights Reserved</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 register-page-white">
                                            <div className="register-page-form">
                                                <div name="signupform" className="row sign-up-form">
                                                    <div className="col-md-12 mb-4">
                                                        <SocialLoginButton
                                                            text={`Sign in with Google`}
                                                            imgSrc={goolgeLogo}
                                                            buttonclasses="btn btn-google ico-left"
                                                            onClick={signInWithGoogleClicked}
                                                            loading={socialLoginStatus === fetchStatus.LOADING}
                                                        />
                                                    </div>
                                                    {/* <div className="col-md-12 mb-4">
                                                        <SocialLoginButton
                                                            text={`Sign in with Linkedin`}
                                                            imgSrc={linkedinLogo}
                                                            buttonclasses="btn btn-google ico-left"
                                                            onClick={linkedInLogin}
                                                            loading={socialLoginStatus === fetchStatus.LOADING}
                                                        />
                                                    </div> */}
                                                    <div className="col-md-12 text-center">
                                                        <div className="separator-line">Or, sign in with your email</div>
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <p className="p-sm input-header">Email</p>
                                                        <EmailText
                                                            id="txtEmail"
                                                            name="txtEmail"
                                                            EmailTextClasses="form-control"
                                                            placeholder="Email"
                                                            required={true}
                                                            errorMessage="Please enter email"
                                                            form={LOGIN_FORM}
                                                            submited={lfSubmitted}
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <p className="p-sm input-header">Password</p>
                                                        <PasswordText
                                                            id="txtPassword"
                                                            name="txtPassword"
                                                            PasswordTextClasses="form-control"
                                                            placeholder="Password"
                                                            required={true}
                                                            errorMessage="Please enter password"
                                                            form={LOGIN_FORM}
                                                            submited={lfSubmitted}
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="reset-password-link">
                                                            <p className="p-sm"><a href="#" className="color--theme">Forgot your password?</a></p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        {loginStatus === fetchStatus.LOADING ?
                                                            <button
                                                                type="submit"
                                                                className="btn btn--theme hover--theme submit"
                                                                disabled>
                                                                Log In
                                                            </button>
                                                            :
                                                            <button
                                                                type="submit"
                                                                className="btn btn--theme hover--theme submit"
                                                                ref={loginButtonRef}
                                                                onClick={(e) => onLoginClicked(e)}>
                                                                Log In
                                                            </button>
                                                        }
                                                    </div>
                                                    <div className="col-md-12">
                                                        <p className="create-account text-center">
                                                            Don't have an account? <Link to="/register" className="color--theme">Sign up</Link>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login