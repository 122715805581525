import React, { useEffect, useState } from 'react';
import meteorRainImg from './../../../assets/images/clipart/meteor-rain.gif';


function Submission() {

    const [countdown, setCountdown] = useState(10); // Starting from 10 seconds

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000); // 1000 milliseconds = 1 second

            return () => clearTimeout(timer);
        } else {
            window.location.href = 'https://getfollowups.com';
        }
    }, [countdown]);

    return (
        <>
            <div className="container justify-content-center col-12 col-md-6 congratulations">
                <div className="animate__animated animate__fadeIn">
                    <div className="card card-wizard">
                        <div className="card-body">
                            <div className="row p-2">
                                <div className="col-md-12 justify-content-center">
                                    <center>
                                        <img src={meteorRainImg} alt="no photo" style={{ width: "20%" }} />
                                        <div className="animate__animated animate__zoomIn">
                                            <h3 className="pt-5">
                                                Congratulations</h3>
                                        </div>
                                    </center>
                                </div>
                            </div>
                            <div className="row p-2">
                                <div className="col-md-12">
                                    <center>
                                        <div className="animate__animated animate__headShake">
                                            <p>Now you are part of FollowUps. We appreciate your involvement and will get back to you shortly.</p>
                                        </div>
                                        <a href="https://getfollowups.com" className="btn btn-primary my-3">
                                            Back
                                        </a>

                                        <div className="alert alert-warning" role="alert">
                                            <p className="mb-0">This page will redirect in {countdown} seconds.</p>
                                        </div>
                                    </center>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Submission;
