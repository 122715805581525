import React, { useEffect, useRef, useState } from 'react';

function SelectorHelperText(props) {
    const [value, setValue] = useState(props.value ? props.value : "");
    const [invalid, setInvalid] = useState(false);
    const [edited, setEdited] = useState(false);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        if (props.required) {
            if (edited || props.submited) {
                if (!value) {
                    setInvalid(true);
                }
                else {
                    setInvalid(false);
                }
            }
            else {
                setInvalid(false);
            }
            setEdited(false);
        }

    }, [value, props.required, edited, props.submited]);

    const onChange = (e, option) => {
        let _e = { ...e };
        _e.target["name"] = props.name;
        _e.target["value"] = option;
        props.onChange && props.onChange(e);
        setValue(e.value);
        setEdited(true);
    };

    const [open, setOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleSelect = (e, option) => {
        onChange(e, option);
        setOpen(false);
    };


    return (
        <>
            <div
                id={`dropdown_helper_${props.id}`}
                className="dropdown"
                ref={dropdownRef}
            >
                <button
                    className="btn btn-light dropdown-toggle w-100 text-start selector-helper-btn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded={open}
                    onClick={() => setOpen(!open)}

                    id={props.id}
                    name={props.name}
                    data-form={props.form ? props.form : ""}
                    data-invalid={invalid}
                    formNoValidate={true}
                    data-required={props.required ? props.required : false}
                    data-value={value}
                    value={value}
                >
                    {value ? (
                        <>
                            {value}
                        </>
                    ) : (
                        <span className="text-muted"> --- Select --- </span>
                    )}
                </button>

                <ul className={`dropdown-menu selector-helper-text-ul ${open ? "show" : ""}`} >
                    {props.options.map((option, index) => (
                        <li key={index} className='m-1'>
                            <button
                                className="dropdown-item"
                                onClick={(e) => handleSelect(e, option.value)}
                            >
                                <div className='selector-helper-text-option-name'>{option.value}</div>
                                <div className="selector-helper-text-option-subname">{option.helperText}</div>
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            {invalid && <span className="error-label">
                <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                {props.errorMessage ? props.errorMessage : "This field is required"}
            </span>}
        </>
    );
}

export default SelectorHelperText;