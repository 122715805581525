import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { BajoAPI, fetchStatus } from "../../../api/client";
import { USER_KEY } from "../../../common/constants";
import { toaster } from '../../../component/Controls/toasts/toaster';
import { getAxiosRequestConfig } from "../../../common/common";
import { isJSON } from "../../../utilities/utilityFunctions";


const initialState = {
    currentStep: '',
    login: {
        status: fetchStatus.IDLE,
        error: null,
        user: undefined
    },
    logout: {
        status: fetchStatus.IDLE,
        error: null
    },
    register: {
        status: fetchStatus.IDLE,
        error: null,
        user: undefined
    },
    forgotPassword: {
        status: fetchStatus.IDLE,
        error: null,
        user: undefined
    },
    setPassword: {
        status: fetchStatus.IDLE,
        error: null,
        user: undefined
    },
    exchangeCode: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    socialLogin: {
        status: fetchStatus.IDLE,
        error: null,
        user: undefined
    },
    profile: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
    },
    modificationProfile: {
        status: fetchStatus.IDLE,
        error: null,
        modifiedResource: undefined
    },
    portals:{
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
    },
    portalLogin:{
        status: fetchStatus.IDLE,
        error: null,
        user: undefined
    }
}

export const accountSlice = createSlice({
    name: 'registerDetails',
    initialState,
    reducers: {
        addCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        updateLoginStatus: (state) => {
            state.login.status = fetchStatus.IDLE;
        },
        updateSocialLoginStatus: (state) => {
            state.socialLogin.status = fetchStatus.IDLE;
        },
        updateProfileStatus: (state) => {
            state.profile.status = fetchStatus.IDLE;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state, action) => {
            state.login.status = fetchStatus.LOADING;
        }).addCase(login.fulfilled, (state, action) => {
            state.login.user = action.payload.data;
            localStorage.setItem(USER_KEY, JSON.stringify(action.payload.data));
            state.login.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(login.rejected, (state, action) => {
            state.login.status = fetchStatus.FAILED;
            // state.socialLogin.error = action.payload.Error;
            state.login.error = action.payload.Error;
            toaster.error(action.payload ? action.payload.Error : "");
        }).addCase(logout.pending, (state, action) => {
            state.logout.status = fetchStatus.LOADING;
        }).addCase(logout.fulfilled, (state, action) => {
            state.login.user = undefined;
            state.logout.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(logout.rejected, (state, action) => {
            state.logout.status = fetchStatus.FAILED;
            state.logout.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(register.pending, (state, action) => {
            state.register.status = fetchStatus.LOADING;
        }).addCase(register.fulfilled, (state, action) => {
            state.login.user = action.payload.data;
            localStorage.setItem(USER_KEY, JSON.stringify(action.payload.data));
            state.register.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(register.rejected, (state, action) => {
            state.register.status = fetchStatus.FAILED;
            // state.register.error = action.error.message;
            state.register.error = action.payload.Error;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(forgotPassword.pending, (state, action) => {
            state.forgotPassword.status = fetchStatus.LOADING;
        }).addCase(forgotPassword.fulfilled, (state, action) => {
            state.forgotPassword.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(forgotPassword.rejected, (state, action) => {
            state.forgotPassword.status = fetchStatus.FAILED;
            state.forgotPassword.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(setPassword.pending, (state, action) => {
            state.setPassword.status = fetchStatus.LOADING;
        }).addCase(setPassword.fulfilled, (state, action) => {
            state.setPassword.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(setPassword.rejected, (state, action) => {
            state.setPassword.status = fetchStatus.FAILED;
            state.setPassword.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(setupExchangeCode.pending, (state, action) => {
            state.exchangeCode.status = fetchStatus.LOADING;
        }).addCase(setupExchangeCode.fulfilled, (state, action) => {
            state.exchangeCode.status = fetchStatus.SUCCEEDED;
            state.exchangeCode.data = action.details;
            toaster.success(action.payload.success);
        }).addCase(setupExchangeCode.rejected, (state, action) => {
            state.exchangeCode.status = fetchStatus.FAILED;
            state.exchangeCode.error = action.payload.error;
            toaster.error(action.payload ? action.payload.Error : "");
        }).addCase(socialLogin.pending, (state, action) => {
            state.socialLogin.status = fetchStatus.LOADING;
        }).addCase(socialLogin.fulfilled, (state, action) => {
            state.socialLogin.user = action.payload.data;
            localStorage.setItem(USER_KEY, JSON.stringify(action.payload.data));
            state.socialLogin.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(socialLogin.rejected, (state, action) => {
            state.socialLogin.status = fetchStatus.FAILED;
            state.socialLogin.error = action.payload.Error;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getProfile.pending, (state, action) => {
            state.profile.status = fetchStatus.LOADING;
        }).addCase(getProfile.fulfilled, (state, action) => {
            state.profile.data = action.payload.profile;
            state.profile.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getProfile.rejected, (state, action) => {
            state.profile.status = fetchStatus.FAILED;
            state.profile.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(updateProfile.pending, (state, action) => {
            state.modificationProfile.status = fetchStatus.LOADING;
        }).addCase(updateProfile.fulfilled, (state, action) => {
            // state.modificationProfile.data = action.payload.lead;
            state.modificationProfile.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(updateProfile.rejected, (state, action) => {
            state.modificationProfile.status = fetchStatus.FAILED;
            state.modificationProfile.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getPortals.pending, (state, action) => {
            state.portals.status = fetchStatus.LOADING;
        }).addCase(getPortals.fulfilled, (state, action) => {
            state.portals.data = action.payload.portals;
            state.portals.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getPortals.rejected, (state, action) => {
            state.portals.status = fetchStatus.FAILED;
            state.portals.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(portalLogin.pending, (state, action) => {
            state.portalLogin.status = fetchStatus.LOADING;
        }).addCase(portalLogin.fulfilled, (state, action) => {
            state.portalLogin.user = action.payload.data;
            localStorage.setItem(USER_KEY, JSON.stringify(JSON.parse(action.payload.data.Data)));
            state.portalLogin.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(portalLogin.rejected, (state, action) => {
            state.portalLogin.status = fetchStatus.FAILED;
            state.portalLogin.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        });
    }
});

export const { addCurrentStep, updateLoginStatus, updateSocialLoginStatus, updateProfileStatus } = accountSlice.actions;


export const login = createAsyncThunk('accounts/login', async (loginModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Identity/login', loginModel);
        return {
            data: response.data,
            success: response.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const socialLogin = createAsyncThunk('accounts/socialLogin', async (loginModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Identity/socialLogin', loginModel);
        return {
            data: response.data,
            success: response.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const logout = createAsyncThunk('accounts/logout', async (logoutModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Account/logout', logoutModel);
        return {
            data: response.data,
            success: response.success
        };

    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const register = createAsyncThunk('accounts/register', async (registerModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Identity/register', registerModel);
        return {
            data: response.data,
            success: response.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const forgotPassword = createAsyncThunk('accounts/forgotPassword', async (forgotPasswordModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Identity/forgotPassword', forgotPasswordModel);
        const data = response.data ? response.data.data : undefined;
        return {
            data: data,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const setPassword = createAsyncThunk('accounts/setPassword', async (setPasswordModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Identity/setPassword', setPasswordModel);
        const data = response.data ? response.data.data : undefined;
        return {
            data: data,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});


export const setupExchangeCode = createAsyncThunk('accounts/setupExchangeCode', async (userModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Process/setupExchangeCode`, userModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let details = undefined;
        if (data && isJSON(data)) {
            details = JSON.parse(data);
        }
        return {
            details: details,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getProfile = createAsyncThunk('accounts/getProfile', async (userModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Process/getProfile', userModel, getAxiosRequestConfig());
        const data = response.data ? response.data.Data : {};
        let profile = undefined;
        if (data && isJSON(data)) {
            profile = JSON.parse(data);
        }
        return {
            profile: profile,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const updateProfile = createAsyncThunk('accounts/updateProfile', async (userModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Process/updateProfile', userModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let profile = undefined;
        if (data && isJSON(data)) {
            profile = JSON.parse(data);
        }
        return {
            profile: profile,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getPortals = createAsyncThunk('accounts/getPortals', async (portalsModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Process/getPortals', portalsModel, getAxiosRequestConfig());
        const data = response.data ? response.data.Data : {};
        let portals = undefined;
        if (data && isJSON(data)) {
            portals = JSON.parse(data);
        }
        return {
            portals: portals,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const portalLogin = createAsyncThunk('accounts/portalLogin', async (portalsModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Process/portalLogin', portalsModel,  getAxiosRequestConfig());
        return {
            data: response.data,
            success: response.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});


export default accountSlice.reducer;

export const selectLoginStatus = state => state.accounts.login.status;
export const selectLoginError = state => state.accounts.login.error;

export const selectLoggedInUser = state => {
    let user = state.accounts.login.user;
    if (!user) {
        user = JSON.parse(localStorage.getItem(USER_KEY));
    }
    return user;
};

export const selectLogoutStatus = state => state.accounts.logout.status;
export const selectLogoutError = state => state.accounts.logout.error;

export const selectRegisterStatus = state => state.accounts.register.status;
export const selectRegisterError = state => state.accounts.register.error;

export const selectForgotPasswordStatus = state => state.accounts.forgotPassword.status;
export const selectForgotPasswordError = state => state.accounts.forgotPassword.error;

export const selectSetPasswordStatus = state => state.accounts.setPassword.status;
export const selectSetPasswordError = state => state.accounts.setPassword.error;


export const selectSetupExchangeCodeStatus = state => state.accounts.exchangeCode.status;
export const selectSetupExchangeCodeError = state => state.accounts.exchangeCode.error;
export const selectSetupExchangeCode = state => state.accounts.exchangeCode.data;

export const selectSocialLoginStatus = state => state.accounts.socialLogin.status;
export const selectSocialLoginError = state => state.accounts.socialLogin.error;

export const selectProfile = state => state.accounts.profile.data;
export const selectProfileStatus = state => state.accounts.profile.status;

export const selectModificationProfileStatus = state => state.accounts.modificationProfile.status;

export const selectPortals = state => state.accounts.portals.data;
export const selectPortalsStatus = state => state.accounts.portals.status;

export const selectPortalLoginStatus = state => state.accounts.portalLogin.status;
export const selectPortalLoginError = state => state.accounts.portalLogin.error;