import { configureStore } from "@reduxjs/toolkit";
import accountsSlice from "./features/accounts/reducerSlices/accountsSlice";
import layoutSlice from "./component/layouts/reducerSlices/layoutSlice";
import userFollowUpProcessSlice from "./features/settings/reducerSlices/userFollowUpProcessSlice";
import dashboardSlice from "./features/dashboard/reducerSlices/dashboardSlice";
import contactsSlice from "./features/contacts/reducerSlices/contactsSlice";
import pageContainerSlice from "./component/Controls/paginators/paginator/reducerSlices/pageContainerSlice";
import vocabularySlice from "./features/settings/reducerSlices/vocabularySlice";
import settingLeadStatusSlice from "./features/settings/reducerSlices/settingLeadStatusSlice";
import settingCallDispositionStatusSlice from "./features/settings/reducerSlices/settingCallDispositionStatusSlice";
import settingICPSlice from "./features/settings/reducerSlices/settingICPSlice";
import settingSequenceAgainSlice from "./features/settings/reducerSlices/settingSequenceAgainSlice";
import hubspotMeetingModalPopUpSlice from "./component/Controls/modals/reducerSlices/hubspotMeetingModalPopUpSlice";
import congratulationsModalPopUpSlice from "./component/Controls/modals/reducerSlices/congratulationsModalPopUpSlice";


export const store = configureStore({
    reducer: {
        accounts: accountsSlice,
        layout: layoutSlice,
        pageContainer: pageContainerSlice,
        userFollowUpProcess: userFollowUpProcessSlice,
        dashboard: dashboardSlice,
        contacts: contactsSlice,
        vocabulary: vocabularySlice,
        settingLeadStatus: settingLeadStatusSlice,
        settingCallDispositionStatus: settingCallDispositionStatusSlice,
        settingICP: settingICPSlice,
        settingSequenceAgain: settingSequenceAgainSlice,
        hubspotMeetingModalPopUp: hubspotMeetingModalPopUpSlice,
        congratulationsModalPopUp: congratulationsModalPopUpSlice
    }
});

export default store;