import React, { useEffect, useState } from 'react';

// import './css/searchBar.css';

function SearchBar(props) {
    const [searchTerm, setSearchTerm] = useState(props.searchTerm ? props.searchTerm : "");

    useEffect(() => {
        setSearchTerm(props.searchTerm);
    }, [props.searchTerm]);

    const onSearch = (e) => {
        let searchTerm = e.target.value;
        setSearchTerm(searchTerm);
        props.onSearch && props.onSearch(e, searchTerm);
        if (!searchTerm) {
            props.onClear && props.onClear(e);
        }
    };

    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            let searchTerm = e.target.value;
            setSearchTerm(searchTerm);
            props.onSearchClicked(e, searchTerm)
        }

    };

    const onClear = (e) => {
        setSearchTerm("");
        props.onClear && props.onClear(e);
    };

    return (
        <div className="search-bar">
            <input
                id={props.id}
                name={props.name}
                type="text"
                className={`form-control search-bar-control ${props.SearchBarClasses ? props.SearchBarClasses : ""}`}
                value={searchTerm}
                placeholder={props.placeholder ? props.placeholder : "Search Records"}
                onChange={(e) => onSearch(e)}
                onKeyDown={(e) => onKeyPress(e)} />
            {searchTerm && <span
                title="Clear"
                className="text-primary icon-clear-search me-3"
                onClick={(e) => onClear(e)}>
                <i className="fa fa-times" aria-hidden="true"></i>
            </span>}
            <span className="search-icon-btn" onClick={(e) => props.onSearchClicked && props.onSearchClicked(e, searchTerm)} title="Search">
                <i className="fa fa-solid fa-magnifying-glass" aria-hidden="true"></i>
            </span>

        </div>
    );
}

export default SearchBar;