import React from 'react';
import { useSelector } from 'react-redux';
import './progress.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { accountService } from '../../features/accounts/services/accountService';
import ProgressProvider from './component/ProgressProvider';


const Progress = (props) => {
    let myState = useSelector((state) => state.accounts);

    const user = accountService.getAuthenticatedUser();
    let name = user && user.FirstName ? user.FirstName : "";

    // let personalCompleted = (myState.currentStep === 'UserFollowUpProcess' || myState.currentStep === 'Employment' || myState.currentStep === 'Education') ? true : false;
    // let connectHubspotInstanceCompleted = (myState.currentStep === 'Education') ? true : false;
    // let userFollowUpProcessCompleted = (myState.currentStep === 'Employment' || myState.currentStep === 'Education') ? true : false;

    let accountCompleted = (myState.currentStep === 'ConnectHubspotInstance' || myState.currentStep === 'UserFollowUpProcess' || myState.currentStep === 'Finsh') ? true : false;
    let connectHubspotInstanceCompleted = (myState.currentStep === 'UserFollowUpProcess' || myState.currentStep === 'Finsh') ? true : false;
    let userFollowUpProcessCompleted = (myState.currentStep === 'Finsh') ? true : false;
    let educationCompleted = false;

    let percentageStart = props.percentageStart ? props.percentageStart : '0';
    const percentage = props.percentage ? props.percentage : '0';


    return (
        <>
            <div className="card-body">
                <div className="row p-md-2">
                    <div className="col-md-12 text-center">
                        <div className="animate__animated animate__swing">
                            <div className="stepper-item-sub-heading">Welcome!</div>
                        </div>
                        <div className="animate__animated animate__bounceIn text-white">
                            <div className="stepper-item-heading">{name ? name : ''}</div>
                        </div>
                    </div>
                    <div className="col-md-12 d-flex justify-content-center mt-5 d-none d-md-flex ">
                        <div style={{ width: 200, height: 200 }}>
                            {/* <CircularProgressbar
                                background={true}
                                value={percentage}
                                text={percentage + `%`}
                                className="<div className='animate__animated animate__fadeIn'>25</div>"
                                styles={buildStyles({
                                    pathColor: '#F02469',
                                    textColor: '#0A253E',
                                    trailColor: '#E6EAF3',
                                    backgroundColor: '#FFF'
                                })}
                            /> */}
                            <div style={{ width: 200, height: 200 }}>
                                <ProgressProvider valueStart={percentageStart} valueEnd={percentage}>
                                    {(value) => <CircularProgressbar
                                        background={true}
                                        value={value}
                                        text={value + `%`}
                                        className="<div className='animate__animated animate__fadeIn'>25</div>"
                                        styles={buildStyles({
                                            pathColor: '#F02469',
                                            textColor: '#0A253E',
                                            trailColor: '#E6EAF3',
                                            backgroundColor: '#FFF',
                                            pathTransitionDuration: 1.5
                                        })}
                                    />}

                                </ProgressProvider>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 text-center mt-5 d-none d-md-block">
                        <div className="animate__animated animate__zoomI text-white">
                            <div className="stepper-item-details">
                                {props.subTitle}
                            </div>
                        </div>
                        {/* <p className="animate__animated animate__zoomIn text-white">complete and Get <span className='text-success'>+10%</span></p> */}
                    </div>
                </div>
            </div>
            <div className="card-footer mb-md-5 border-top-0">
                <div className="stepper-wrapper">
                    <div className={`stepper-item ${myState.currentStep === 'Account' ? 'active' : accountCompleted && 'completed'}`}>
                        <div className="step-counter">{accountCompleted ? <i className="fa fa-check" aria-hidden="true"></i> : <i className="fa fa-check" aria-hidden="true"></i>}</div>
                        <div className="step-name">Account</div>
                    </div>
                    <div className={`stepper-item ${myState.currentStep === 'ConnectHubspotInstance' ? 'active' : connectHubspotInstanceCompleted && 'completed'}`}>
                        <div className="step-counter">{connectHubspotInstanceCompleted ? <i className="fa fa-check" aria-hidden="true"></i> : <i className="fa fa-check" aria-hidden="true"></i>}</div>
                        <div className="step-name">Connect Hubspot Instance</div>
                    </div>
                    <div className={`stepper-item ${myState.currentStep === 'UserFollowUpProcess' ? 'active' : userFollowUpProcessCompleted && 'completed'}`}>
                        <div className="step-counter">{userFollowUpProcessCompleted ? <i className="fa fa-check" aria-hidden="true"></i> : <i className="fa fa-check" aria-hidden="true"></i>}</div>
                        <div className="step-name">User Filter</div>
                    </div>
                    <div className={`stepper-item ${myState.currentStep === 'Education' ? 'active' : educationCompleted && 'completed'}`}>
                        <div className="step-counter">{educationCompleted ? <i className="fa fa-check" aria-hidden="true"></i> : <i className="fa fa-check" aria-hidden="true"></i>}</div>
                        <div className="step-name">Finsh</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Progress;