import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCurrentStep, selectSetupExchangeCodeStatus, setupExchangeCode } from '../reducerSlices/accountsSlice';
import { accountService } from '../services/accountService';
import { useNavigate } from 'react-router-dom';
import Logo from '../../../component/logo/logo';
import { fetchStatus } from '../../../api/client';
import Progress from '../../../component/Progress/Progress';
import EllipsisLoader from '../../../component/Controls/loaders/ellipsisLoader';
import ImageLoader from '../../../component/Controls/loaders/imageLoader';
import ConnectionLoaderEffect from '../../../component/Animation/ConnectionLoaderEffect';

const Callback = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    dispatch(addCurrentStep('ConnectHubspotInstance'));

    let storedACInfo = accountService.getAuthenticatedUser();
    const setupExchangeCodeStatus = useSelector(selectSetupExchangeCodeStatus);

    // Check if the ACInformation exists and is valid
    let isLoggedIn = accountService.isAuthenticated();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code'); // Get the authorization code from URL

        if (accountService.isAuthenticated() && code) {
            exchangeCodeForToken(code);
        }
    }, [isLoggedIn]);

    const exchangeCodeForToken = async (code) => {
        storedACInfo = JSON.stringify(storedACInfo);
        await dispatch(setupExchangeCode({
            code, storedACInfo
        }));
    }

    useEffect(() => {
        if (setupExchangeCodeStatus === fetchStatus.SUCCEEDED) {
            navigate("/settings/userFollowUpProcess");
        }
    }, [setupExchangeCodeStatus]);


    return (
        <>
            {(setupExchangeCodeStatus === fetchStatus.LOADING
            ) && <div className="app-container-loader">
                    <ImageLoader />
                </div>}

            {/* <div className="container text-center new-card-ui p-5">
                <div className="row">
                    <div className="col-auto mb-5">
                        <Logo />
                    </div>
                    <div className="col-12">
                        <>
                            <div className="alert alert-warning" role="alert">
                                <h3 className="alert-heading">Installation</h3>
                                <h5 className="text-danger font-weight-bold">Do not close or refresh this page.</h5>
                                <hr />
                                <p className="mb-0">You’ll be notified when the installation is complete.</p>
                            </div>
                        </>
                    </div>
                </div>
            </div> */}



            <div className="container wizard-container">
                <div className="row">
                    <div className="col-md-8 card card-left d-flex align-items-stretch p-0">
                        <div className="card-header">
                            <div className="row p-2">
                                <div className="col-md-12">
                                    <div className="card-left-header">Connecting Your HubSpot Instance</div>
                                    <div className="card-left-sub-header">Securely linking your HubSpot portal with our system.</div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body mb-5 mb-md-0 animate__animated animate__fadeIn d-flex justify-content-center align-items-center">
                            {/* <div className="row p-2">
                                <div className="col-md-12">
                                    <div className="alert alert-warning text-center " role="alert">
                                        <h3 className="alert-heading">Installation</h3>
                                        <h5 className="text-danger font-weight-bold">Do not close or refresh this page.</h5>
                                        <hr />
                                        <p className="mb-0">You’ll be notified when the installation is complete.</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className="row p-2">
                                <div className="col-md-12">
                                    <ConnectionLoaderEffect />
                                </div>
                            </div>
                        </div>
                        <div className="card-footer bottom-0 px-4 py-2 w-100">
                            <div className="row">
                                <div className="col-md-12">
                                    {
                                        (setupExchangeCodeStatus === fetchStatus.LOADING) ?
                                            <div className="float-end my-2"><EllipsisLoader /></div>
                                            :
                                            <>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 card card-right d-flex align-items-stretch p-0">
                        <Progress percentageStart="10" percentage="40" getPercentage="15" subTitle="Connect Hubspot Instance" currentPage="personalInfo1" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Callback;
