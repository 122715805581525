import { unwrapResult } from "@reduxjs/toolkit";
import { USER_KEY, Role } from "../../../common/constants";
import store from '../../../store';
import { login, register } from './../reducerSlices/accountsSlice';

export const accountService = {
    isAuthenticated: () => {
        return localStorage.getItem(USER_KEY) ? true : false;
    },
    getAuthenticatedUser: () => {
        let user;
        if (accountService.isAuthenticated()) {
            user = JSON.parse(localStorage.getItem(USER_KEY));
        }
        return user || { id: "" };
    },
    login: async (loginModel) => {
        const result = await store.dispatch(login(loginModel));
        const data = unwrapResult(result);
        if (data && data.id) {

        }
    },
    logout: () => {
        if (accountService.isAuthenticated()) {
            localStorage.removeItem(USER_KEY);
        }
    },
    getToken: () => {
        const user = accountService.getAuthenticatedUser();
        return user ? `Bearer fake-jwt-token ${user.JwtToken}` : "";
    },
    isAdmin: () => {
        const user = accountService.getAuthenticatedUser();
        return user && (user.role === Role.ADMIN || user.Role === Role.ADMIN)? true : false;
    },
    isApplicant: () => {
        const user = accountService.getAuthenticatedUser();
        return user && (user.role === Role.APPLICANT || user.Role === Role.APPLICANT) ? true : false;
    },
    register: async (registerModel) => {
        const result = await store.dispatch(register(registerModel));
        const data = unwrapResult(result);
        if (data && data.id) {
            // localStorage.setItem(USER_KEY, JSON.stringify(data));
            // const user = accountService.getAuthenticatedUser();
        }
    },
    getLoggedInUserId: () => {
        const user = accountService.getAuthenticatedUser();
        return user && user.id ? user.id : "";
    },
    getLoggedInUserRole: () => {
        const user = accountService.getAuthenticatedUser();
        return user && user.role ? user.role : "";
    },
    getLoggedInUserLocationId: () => {
        const user = accountService.getAuthenticatedUser();
        return user && user.locationId ? user.locationId : "";
    },
    isAuthorized: () => {
        if (accountService.isAuthenticated()
            && (accountService.isAdmin() || accountService.isApplicant())) {
            // && accountService.isApplicant()) {
            return true;
        }
        else {
            localStorage.removeItem(USER_KEY);
            return false;
        }

    },
    isCompanySetup: () => {
        // if (accountService.isAuthenticated()) {
            const user = accountService.getAuthenticatedUser();
            return user && user.IsCompanySetup === true ? true : false;
        // }

    },
    isRegistrationComplete: () => {
        // if (accountService.isAuthenticated()) {
            const user = accountService.getAuthenticatedUser();
            return user && user.IsRegistrationComplete === true ? true : false;
        // }

    },
    isLicensed: () => {
        // if (accountService.isAuthenticated()) {
            const user = accountService.getAuthenticatedUser();
            return user && user.IsLicensed === true ? true : false;
        // }

    }
}