import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getPortals, portalLogin, selectPortalLoginError, selectPortalLoginStatus, selectPortals, selectPortalsStatus } from '../reducerSlices/accountsSlice';
import { fetchStatus } from '../../../api/client';
import { useNavigate } from 'react-router-dom';
import ImageLoader from '../../../component/Controls/loaders/imageLoader';


function PortalSelection() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedPortal, setSelectedPortal] = useState(null);

    const portalsStatus = useSelector(selectPortalsStatus);
    const portals = useSelector(selectPortals);

    const portalLoginStatus = useSelector(selectPortalLoginStatus);
    const portalLoginError = useSelector(selectPortalLoginError);

    const _loadPortals = async () => {
        await dispatch(getPortals({
        }));
    }

    const _getPortalLogin = async (emailId) => {
        await dispatch(portalLogin({
            id: emailId,
            data: ""
        }));
    };

    useEffect(() => {
        if (portalsStatus == fetchStatus.IDLE) {
            _loadPortals()
        }
    }, [portalsStatus]);

    useEffect(() => {
        if (portalLoginStatus == fetchStatus.SUCCEEDED) {
            navigate("/dashboard");
        }
    }, [portalLoginStatus])

    const handleSelectPortal = (portalEmailId) => {
        setSelectedPortal(portalEmailId);
        _getPortalLogin(portalEmailId);
    };

    return (
        <>
            {(portalsStatus === fetchStatus.LOADING ||
                portalLoginStatus === fetchStatus.LOADING
            ) && <div className="app-container-loader">
                    <ImageLoader />
                </div>}

            <div className="vh-100 d-flex justify-content-center align-items-center">
                <div className="card portal-card p-5">
                    <h2 className="text-center mb-4">Select Portal</h2>
                    <p className="text-center mb-4">Please select a portal to continue.</p>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="">Company Name</th>
                                    <th className="ps-5">Company Email</th>
                                    {/* <th className="ps-5">First Name</th> */}
                                    {/* <th className="ps-5">Last Name</th> */}
                                    <th className="ps-5">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {portals && portals.map(d => (
                                    <tr key={d.Email}>
                                        <td>{d.CompanyName}</td>
                                        <td className="ps-5">{d.Email}</td>
                                        {/* <td className="ps-5">{d.FirstName}</td> */}
                                        {/* <td className="ps-5">{d.LastName}</td> */}
                                        <td className="ps-5">
                                            <button className="btn btn-primary" onClick={() => handleSelectPortal(d.Email)}>Select</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PortalSelection