import React from 'react'
import followupsLogo from '../../assets/logo/followups-logo-light.png';

function Logo() {
  return (
    <>
      <img src={followupsLogo} className="nav-logo" alt="logo" />
    </>
  )
}

export default Logo