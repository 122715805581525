import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateLayout } from '../../../component/layouts/reducerSlices/layoutSlice';
import Vocabulary from './components/Vocabulary';
import UserFollowUp from './UserFollowUp';
import SettingLeadStatus from './components/SettingLeadStatus';
import SettingCallDispositionStatus from './components/SettingCallDispositionStatus';
import SettingICP from './components/SettingICP';
import { accountService } from '../../accounts/services/accountService';
import SettingSequenceAgain from './components/SettingSequenceAgain';


const Setting = () => {
  const dispatch = useDispatch();
  const [pageLoaded, setPageLoaded] = useState(false);
  const isAdmin = accountService.isAdmin();

  useEffect(() => {
    if (!pageLoaded) {
      dispatch(updateLayout({
        page: 2,
        title: "Settings"
      }));
      setPageLoaded(true);
    }
  });

  const [selectedLink, setSelectedLink] = useState(1);

  const handleLinkClick = (e, id) => {
    e.preventDefault();
    setSelectedLink(id);
  };


  return (
    <>
      <div className="px-1 pt-4">
        <div className="row">
          <div className="col-md-2">
            <div className="card setting-card">
              <p className="setting-card-menu-title">Settings</p>
              <div className="setting-card-menu">
                <a href="#" className={`${selectedLink === 1 ? 'active' : ''}`} onClick={(e) => handleLinkClick(e, 1)}><i className="fa fa-handshake pe-2" />User Filter</a>
                <a href="#" className={`${selectedLink === 2 ? 'active' : ''}`} onClick={(e) => handleLinkClick(e, 2)}><i className="fa fa-book pe-2" />Vocabulary</a>
                <a href="#" className={`${selectedLink === 3 ? 'active' : ''}`} onClick={(e) => handleLinkClick(e, 3)}><i className="fa fa-clipboard-list pe-2" />Lead Status</a>
                <a href="#" className={`${selectedLink === 4 ? 'active' : ''}`} onClick={(e) => handleLinkClick(e, 4)}><i className="fa fa-phone-alt pe-2" />Call Disposition Status</a>
                {/* {isAdmin && */}
                <>
                  <a href="#" className={`${selectedLink === 5 ? 'active' : ''}`} onClick={(e) => handleLinkClick(e, 5)}><i className="fa fa-bullseye pe-2" />ICP</a>
                  <a href="#" className={`${selectedLink === 6 ? 'active' : ''}`} onClick={(e) => handleLinkClick(e, 6)}><i className="fa fa-history pe-2" />Sequence Again</a>
                </>
                {/* } */}
              </div>
            </div>
          </div>
          <div className="col-md-10">
            <div className="default-root-container detail-container p-4">
              <div className="col-md-12">
                {selectedLink === 1 && <UserFollowUp />}
                {selectedLink === 2 && <Vocabulary />}
                {selectedLink === 3 && <SettingLeadStatus />}
                {selectedLink === 4 && <SettingCallDispositionStatus />}
                {selectedLink === 5 && <SettingICP />}
                {selectedLink === 6 && <SettingSequenceAgain />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Setting;