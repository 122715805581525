import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getHubSpotAllUsers, processDataByHubSpotUserIds, selectHubSpotAllUsers, selectProcessDataByHubSpotUserIdsStatus, selectStatus, updateProcessDataByHubSpotUserIdsStatus, updateStatus } from '../reducerSlices/userFollowUpProcessSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatus } from '../../../api/client';
import { accountService } from '../../accounts/services/accountService';
import { useNavigate } from 'react-router-dom';
import Logo from '../../../component/logo/logo';
import Progress from '../../../component/Progress/Progress';
import { addCurrentStep } from '../../accounts/reducerSlices/accountsSlice';
import EllipsisLoader from '../../../component/Controls/loaders/ellipsisLoader';
import BackButton from '../../../component/Controls/buttons/backButton';
import ContinueButton from '../../../component/Controls/buttons/continueButton';
import ImageLoader from '../../../component/Controls/loaders/imageLoader';
import { showHubspotMeetingModal } from '../../../component/Controls/modals/reducerSlices/hubspotMeetingModalPopUpSlice';
import { updateIsFirstTimeDashboardVisit } from '../../dashboard/reducerSlices/dashboardSlice';


const UserFollowUpProcess = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = accountService.getAuthenticatedUser();
    const _users = useSelector(selectHubSpotAllUsers);
    const usersRequestStatus = useSelector(selectStatus);

    const processDataByHubSpotUserIdsStatus = useSelector(selectProcessDataByHubSpotUserIdsStatus);


    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    dispatch(addCurrentStep('UserFollowUpProcess'));

    const _loadUsers = async () => {
        await dispatch(getHubSpotAllUsers({}));
    }

    useEffect(() => {
        if (usersRequestStatus === fetchStatus.IDLE) {
            _loadUsers();
        }
    }, [usersRequestStatus, dispatch]);

    useEffect(() => {
        if (usersRequestStatus === fetchStatus.SUCCEEDED) {
            let _records = _users;
            setUsers(_records);
            const filteredUsers = _users.filter(u => u["allowedHubSpotOwnersId"] === true).map(u => u.id);
            setSelectedUsers(filteredUsers);
        }
    }, [usersRequestStatus]);

    // useEffect(()=>{
    //     alert( usersRequestStatus)
    // },[usersRequestStatus])

    const handleCheckboxChange = (userId) => {
        setSelectedUsers((prevSelected) =>
            prevSelected.includes(userId)
                ? prevSelected.filter((id) => id !== userId)
                : [...prevSelected, userId]
        );
    };

    const handleSelectAllChange = () => {
        if (selectedUsers.length === users.length) {
            setSelectedUsers([]);
        } else {
            const allUserIds = users.map((user) => user.id);
            setSelectedUsers(allUserIds);
        }
    };

    const submitSelectedUsers = async () => {
        if (selectedUsers.length === 0) {
            alert('Please select at least one user for processing.');
            return;
        }

        dispatch(processDataByHubSpotUserIds({
            UserIds: selectedUsers,
        }));
    };

    const _showHubspotMeetingModal = () => {
        dispatch(showHubspotMeetingModal({
            title: <></>,
            id: ""
        }));
    }

    useEffect(() => {
        if (processDataByHubSpotUserIdsStatus === fetchStatus.SUCCEEDED) {
            // navigate('/thank-you')
            dispatch(updateProcessDataByHubSpotUserIdsStatus());
            // _showHubspotMeetingModal();
            dispatch(updateIsFirstTimeDashboardVisit(true));
            navigate('/dashboard');
        }
    }, [processDataByHubSpotUserIdsStatus]);

    const onDashboardClicked = () => {
        navigate("/dashboard");
    }


    return (
        <>
            {(usersRequestStatus === fetchStatus.LOADING
            ) && <div className="app-container-loader">
                    <ImageLoader />
                </div>}

            <div className="container wizard-container">
                <div className="row">
                    <div className="col-md-8 card card-left d-flex align-items-stretch p-0">
                        <div className="card-header">
                            <div className="row p-2">
                                <div className="col-md-12">
                                    <div className="card-left-header">User Filter For Followups Process</div>
                                    {/* <div className="card-left-header">Enable AI Services for Contacts Owners</div> */}
                                    <div className="card-left-sub-header">AI processing is available only for contacts whose owners are selected below</div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body mb-5 mb-md-0 animate__animated animate__fadeIn">
                            <div className="row p-2">
                                <div className="col-md-12">
                                    <div className="table-responsive">
                                        <table className="table table-hover custom-table">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            onChange={handleSelectAllChange}
                                                            checked={selectedUsers.length === users.length && users.length > 0}
                                                        />
                                                    </th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Email</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users.map((user) => (
                                                    <tr key={user.id}>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                onChange={() => handleCheckboxChange(user.id)}
                                                                checked={selectedUsers.includes(user.id)}
                                                            />
                                                        </td>
                                                        <td>{user.firstName} {user.lastName}</td>
                                                        <td>{user.email}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer bottom-0 px-4 py-2 w-100">
                            <div className="row">
                                <div className="col-md-12">
                                    {
                                        (usersRequestStatus === fetchStatus.LOADING ||
                                            processDataByHubSpotUserIdsStatus === fetchStatus.LOADING
                                        ) ? <div className="float-end my-2"><EllipsisLoader /></div>
                                            :
                                            <>
                                                {/* <BackButton onBackClicked={onBack} /> */}
                                                <div className="float-end">
                                                    <ContinueButton
                                                        disabled={selectedUsers.length === 0}
                                                        onClick={(e) => submitSelectedUsers(e)}
                                                    />
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 card card-right d-flex align-items-stretch p-0">
                        <Progress percentageStart="40" percentage="70" getPercentage="15" subTitle="User Filter" currentPage="personalInfo1" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserFollowUpProcess;