import {
    createSlice,
    createAsyncThunk
}
    from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON } from '../../../utilities/utilityFunctions';

const initialState = {
    single: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: undefined
    },
    modification: {
        status: fetchStatus.IDLE,
        error: null
    },
};

export const getSettingCallDispositionStatus = createAsyncThunk('settingCallDispositionStatus/getSettingCallDispositionStatus', async (settingCallDispositionStatusModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Process/getSettingCallDispositionStatus`, settingCallDispositionStatusModel, getAxiosRequestConfig());
        const data = response.data ? response.data.Data : undefined;
        let settingCallDispositionStatus = undefined;
        if (data && isJSON(data)) {
            settingCallDispositionStatus = JSON.parse(data);
        }
        return {
            settingCallDispositionStatus: settingCallDispositionStatus,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const updateSettingCallDispositionStatus = createAsyncThunk('settingCallDispositionStatus/updateSettingCallDispositionStatus', async (leadModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Process/updateSettingCallDispositionStatus`, leadModel, getAxiosRequestConfig());
        const data = response.data ? response.data.Data : undefined;
        let settingCallDispositionStatus = undefined;
        if (data && isJSON(data)) {
            settingCallDispositionStatus = JSON.parse(data);
        }
        return {
            settingCallDispositionStatus: settingCallDispositionStatus,
            success: response.data.Success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const settingCallDispositionStatusSlice = createSlice({
    name: 'settingCallDispositionStatus',
    initialState,
    reducers: {
        updateSingleStatus: (state) => {
            state.single.status = fetchStatus.IDLE;
        },
        updateModificationStatus: (state) => {
            state.modification.status = fetchStatus.IDLE;
        },
        updateSingleData: (state, _data) => {
            if (_data.payload){
                state.single.data = {...state.single.data, ..._data.payload}
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSettingCallDispositionStatus.pending, (state, action) => {
            state.single.status = fetchStatus.LOADING;
        })
            .addCase(getSettingCallDispositionStatus.fulfilled, (state, action) => {
                state.single.data = action.payload.settingCallDispositionStatus;
                state.single.status = fetchStatus.SUCCEEDED;
                toaster.success(action.payload.success);
            })
            .addCase(getSettingCallDispositionStatus.rejected, (state, action) => {
                state.single.status = fetchStatus.FAILED;
                state.single.error = action.error.message;
                toaster.error(action.payload ? action.payload.error : "");
            }).addCase(updateSettingCallDispositionStatus.pending, (state, action) => {
                state.modification.status = fetchStatus.LOADING;
            }).addCase(updateSettingCallDispositionStatus.fulfilled, (state, action) => {
                state.modification.status = fetchStatus.SUCCEEDED;
                toaster.success(action.payload.success);
            }).addCase(updateSettingCallDispositionStatus.rejected, (state, action) => {
                state.modification.status = fetchStatus.FAILED;
                state.modification.error = action.error.message;
                toaster.error(action.payload ? action.payload.error : "");
            });
    }
});

export const {
    updateSingleStatus, updateModificationStatus, updateSingleData
} = settingCallDispositionStatusSlice.actions;

export default settingCallDispositionStatusSlice.reducer

export const selectSettingCallDispositionStatus = (state) => {
    return state.settingCallDispositionStatus.single ? state.settingCallDispositionStatus.single.data : undefined;
}
export const selectSettingCallDispositionStatusStatus = state => state.settingCallDispositionStatus.single.status;
export const selectSettingCallDispositionStatusError = state => state.settingCallDispositionStatus.single.error;

export const selectModificationStatus = state => state.settingCallDispositionStatus.modification.status;
export const selectModificationError = state => state.settingCallDispositionStatus.modification.error;