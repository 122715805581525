import React, { useEffect, useState } from 'react';
import './css/connectionLoaderEffect.css';


function ConnectionLoaderEffect() {
    const steps = [
        { icon: "fa-plug", text: "Initializing connection...", color: "#7b5dd6" },
        { icon: "fa-key", text: "Authenticating credentials...", color: "#f39c12" },
        { icon: "fa-shield-alt", text: "Verifying user identity...", color: "#27ae60" },
        { icon: "fa-database", text: "Fetching required data...", color: "#3498db" },
        { icon: "fa-server", text: "Synchronizing server...", color: "#8e44ad" },
        { icon: "fa-cog", text: "Processing information...", color: "#e67e22" },
        { icon: "fa-sync-alt", text: "Optimizing system...", color: "#2ecc71" },
        { icon: "fa-redo", text: "Refreshing connection...", color: "#e74c3c" },
    ];

    const [index, setIndex] = useState(0);
    const [showStep, setShowStep] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowStep(false);
            setTimeout(() => {
                setIndex((prevIndex) => (prevIndex + 1) % steps.length);
                setShowStep(true);
            }, 1000);
        }, 3000);

        return () => clearTimeout(timeout);
    }, [index]);

    return (
        <div className="ConnectionLoaderEffect-container">
            <div className="mb-4">
                <h3 className="ConnectionLoaderEffect-title">
                    Setting Things Up
                    <i className="fa fa-cog mx-2"></i>
                </h3>
            </div>
            <div className="ConnectionLoaderEffect-glow"></div>
            <div className={`ConnectionLoaderEffect-step ${showStep ? "show" : ""}`}>
                <i
                    className={`fa ${steps[index].icon} ConnectionLoaderEffect-icon me-2`}
                    style={{ color: steps[index].color }}
                ></i>
                {steps[index].text}
            </div>
            <div className="ConnectionLoaderEffect-dots">
                <div className="ConnectionLoaderEffect-dot"></div>
                <div className="ConnectionLoaderEffect-dot"></div>
                <div className="ConnectionLoaderEffect-dot"></div>
            </div>
        </div>
    );
}

export default ConnectionLoaderEffect;