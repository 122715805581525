import {
    createSlice,
    createAsyncThunk
}
    from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON } from '../../../utilities/utilityFunctions';

const initialState = {
    single: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: undefined
    },
    modification: {
        status: fetchStatus.IDLE,
        error: null
    },
};

export const getSettingLeadStatus = createAsyncThunk('settingLeadStatus/getSettingLeadStatus', async (settingLeadStatusModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Process/getSettingLeadStatus`, settingLeadStatusModel, getAxiosRequestConfig());
        const data = response.data ? response.data.Data : undefined;
        let settingLeadStatus = undefined;
        if (data && isJSON(data)) {
            settingLeadStatus = JSON.parse(data);
        }
        return {
            settingLeadStatus: settingLeadStatus,
            success: response.data.Success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const updateSettingLeadStatus = createAsyncThunk('settingLeadStatus/updateSettingLeadStatus', async (leadModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Process/updateSettingLeadStatus`, leadModel, getAxiosRequestConfig());
        const data = response.data ? response.data.Data : undefined;
        let settingLeadStatus = undefined;
        if (data && isJSON(data)) {
            settingLeadStatus = JSON.parse(data);
        }
        return {
            settingLeadStatus: settingLeadStatus,
            success: response.data.Success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const settingLeadStatusSlice = createSlice({
    name: 'settingLeadStatus',
    initialState,
    reducers: {
        updateSingleStatus: (state) => {
            state.single.status = fetchStatus.IDLE;
        },
        updateModificationStatus: (state) => {
            state.modification.status = fetchStatus.IDLE;
        },
        updateSingleData: (state, _data) => {
            if (_data.payload){
                state.single.data = {...state.single.data, ..._data.payload}
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSettingLeadStatus.pending, (state, action) => {
            state.single.status = fetchStatus.LOADING;
        })
            .addCase(getSettingLeadStatus.fulfilled, (state, action) => {
                state.single.data = action.payload.settingLeadStatus;
                state.single.status = fetchStatus.SUCCEEDED;
                toaster.success(action.payload.success);
            })
            .addCase(getSettingLeadStatus.rejected, (state, action) => {
                state.single.status = fetchStatus.FAILED;
                state.single.error = action.error.message;
                toaster.error(action.payload ? action.payload.Error : "");
            }).addCase(updateSettingLeadStatus.pending, (state, action) => {
                state.modification.status = fetchStatus.LOADING;
            }).addCase(updateSettingLeadStatus.fulfilled, (state, action) => {
                state.modification.status = fetchStatus.SUCCEEDED;
                toaster.success(action.payload.success);
            }).addCase(updateSettingLeadStatus.rejected, (state, action) => {
                state.modification.status = fetchStatus.FAILED;
                state.modification.error = action.error.message;
                toaster.error(action.payload ? action.payload.error : "");
            });
    }
});

export const {
    updateSingleStatus, updateModificationStatus, updateSingleData
} = settingLeadStatusSlice.actions;

export default settingLeadStatusSlice.reducer

export const selectSettingLeadStatus = (state) => {
    return state.settingLeadStatus.single ? state.settingLeadStatus.single.data : undefined;
}
export const selectSettingLeadStatusStatus = state => state.settingLeadStatus.single.status;
export const selectSettingLeadStatusError = state => state.settingLeadStatus.single.error;

export const selectModificationStatus = state => state.settingLeadStatus.modification.status;
export const selectModificationError = state => state.settingLeadStatus.modification.error;