import React, { useEffect, useState } from 'react';
import PageNavigation from './pageNavigation';

import {
    selectAllItems, selectCurrentPage,
    updateCurrentPage, updateItems
} from './reducerSlices/pageContainerSlice';
import { useDispatch, useSelector } from 'react-redux';
import Collection from '../collection';
import { fetchStatus } from '../../../../api/client';
import { isObjectArray } from '../../../../utilities/utilityFunctions';
import PageNavigationLoadMore from './pageNavigationLoadMore';
import noDataImg from './../../../../assets/images/clipart/no-data.svg';

function PageContainer(props) {

    const dispatch = useDispatch();

    // Calculate the number of header columns dynamically
    const headerCount = React.Children.toArray(props.tableHeaderRow.props.children).length;

    const [infinite, setInfinite] = useState(props.infinite ? props.infinite : false);

    const _getTotalPageCount = (totalPageItems, itemsPerPage) => {
        if (totalPageItems % itemsPerPage === 0) {
            return totalPageItems / itemsPerPage;
        }
        return Math.floor(totalPageItems / itemsPerPage) + 1;
    };

    const currentPage = props.currentPage;
    const [pageRecords, setPageRecords] = useState(new Collection());
    const [pageCount, setPageCount] = useState(0);

    let totalPageItems = props.totalPageItems ? props.totalPageItems : 0;
    let itemsPerPage = (props.itemsPerPage || props.itemsPerPage >= 5) ? props.itemsPerPage : 10;
    let totalVisiblePageNumbers = (props.totalVisiblePageNumbers || props.totalVisiblePageNumbers >= 5) ? props.itemsPerPage : 5;

    let pageItems = props.pageItems;

    let totalPages = _getTotalPageCount(totalPageItems, itemsPerPage);

    useEffect(() => {
        if (props.pageRecords && props.pageRecords.Count > 0) {
            setPageRecords(props.pageRecords);
        }
        else {
            setPageRecords(new Collection());
        }
    }, [props.pageRecords, currentPage])

    const onPageClicked = (page) => {
        if (!pageRecords.Contains(page)) {
            props.onPageChanged(page, true);
        }
        else {
            props.onPageChanged(page, false);
        }
    }
    return (
        <>
            {!infinite ?
                <>
                    <>
                        {props.isItemsLoading === fetchStatus.LOADING ?
                            <>
                                <div className="table-responsive">
                                    <table className="table table-bordered table-hover">
                                        <thead className="thead-light">
                                            {props.tableHeaderRow}
                                        </thead>
                                        <tbody>
                                            {props.itemsLoadingContent}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                            :
                            <>
                                <div className="table-responsive">
                                    <table className="table table-bordered table-hover">
                                        <thead className="thead-light">
                                            {props.tableHeaderRow}
                                        </thead>
                                        <tbody>
                                            {
                                                pageRecords.Get(currentPage)
                                                    && isObjectArray(pageRecords.Get(currentPage).records)
                                                    && pageRecords.Get(currentPage).records.length > 0 ?
                                                    pageRecords.Get(currentPage).records :
                                                    <>
                                                        <tr>
                                                            <td colSpan={headerCount > 0 ? headerCount : 1}>
                                                                <div className="data-not-found p-5">
                                                                    <img src={noDataImg} />
                                                                    <p className="title">No records found</p>
                                                                    <p className="description">Please refresh search filters</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </>}
                    </>
                    <PageNavigation
                        selectedPage={currentPage}
                        totalPages={totalPages}
                        totalVisiblePageNumbers={totalVisiblePageNumbers}
                        onPageClicked={onPageClicked}
                        totalPageItems={totalPageItems}
                        numberOfColumns={props.numberOfColumns}
                        itemsPerPage={itemsPerPage}
                    />
                </>
                :
                <>
                    <div className="right-content p-2">
                        {props.isItemsLoading === fetchStatus.LOADING ?
                            <>
                                {props.itemsLoadingContent}
                                {props.pageRecordsCardClassName &&
                                    <>
                                        <div className={`${props.pageRecordsCardClassName} invisible-card`} />
                                        <div className={`${props.pageRecordsCardClassName} invisible-card`} />
                                        <div className={`${props.pageRecordsCardClassName} invisible-card`} />
                                        <div className={`${props.pageRecordsCardClassName} invisible-card`} />
                                        <div className={`${props.pageRecordsCardClassName} invisible-card`} />
                                        <div className={`${props.pageRecordsCardClassName} invisible-card`} />
                                        <div className={`${props.pageRecordsCardClassName} invisible-card`} />
                                    </>
                                }
                            </>
                            :
                            <>
                                {
                                    pageRecords.Get(currentPage)
                                        && isObjectArray(pageRecords.Get(currentPage).records)
                                        && pageRecords.Get(currentPage).records.length > 0 ?
                                        pageRecords.GetAll(currentPage) :
                                        <div className="data-not-found-container">
                                            <div className="data-not-found">
                                                <img src={noDataImg} />
                                                <p className="title">No records found</p>
                                                <p className="description">Please refresh search filters</p>
                                            </div>
                                        </div>
                                }
                                {props.pageRecordsCardClassName &&
                                    <>
                                        <div className={`${props.pageRecordsCardClassName} invisible-card`} />
                                        <div className={`${props.pageRecordsCardClassName} invisible-card`} />
                                        <div className={`${props.pageRecordsCardClassName} invisible-card`} />
                                        <div className={`${props.pageRecordsCardClassName} invisible-card`} />
                                        <div className={`${props.pageRecordsCardClassName} invisible-card`} />
                                        <div className={`${props.pageRecordsCardClassName} invisible-card`} />
                                        <div className={`${props.pageRecordsCardClassName} invisible-card`} />
                                    </>
                                }
                            </>}
                    </div>
                    <PageNavigationLoadMore
                        selectedPage={currentPage}
                        totalPages={totalPages}
                        totalVisiblePageNumbers={totalVisiblePageNumbers}
                        onPageClicked={onPageClicked}
                        totalPageItems={totalPageItems}
                        numberOfColumns={props.numberOfColumns}
                        itemsPerPage={itemsPerPage}
                    />
                </>
            }
        </>
    );
}

export default PageContainer;