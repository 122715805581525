import React, { useEffect, useState } from 'react';
import { Link, useNavigate as useHistory, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectRegisterError, selectRegisterStatus, selectSocialLoginError, selectSocialLoginStatus, socialLogin, updateLoginStatus, updateProfileStatus, updateSocialLoginStatus } from '../reducerSlices/accountsSlice';
import { fetchStatus, linkedInOptions, redirectHubSpotMarketPlace } from '../../../api/client';
import { accountService } from '../services/accountService';
import { isFormValid } from '../../../component/Controls/services/formService';
// import { handlerError } from '../../../common/common';
import InputGroup from '../../../component/Controls/custom/inputGroup/inputGroup';
import ShortText from '../../../component/Controls/custom/textbox/shortText';
import PhoneText from '../../../component/Controls/custom/textbox/phoneText';
import EmailText from '../../../component/Controls/custom/textbox/emailText';
import PasswordText from '../../../component/Controls/custom/textbox/passwordText';
import { registerDetailsEN } from '../services/registerDetails';
import RegisterButton from '../../../component/Controls/custom/buttons/registerButton';
import DateText from '../../../component/Controls/custom/textbox/dateText';
import Selector from '../../../component/Controls/custom/selectors/selector';
import Logo from '../../../component/logo/logo';
import registerPageImg from '../../../assets/images/register-page.png';
import '../../../assets/css/accounts.css';
import '../../../assets/css/register.css';
import goolgeLogo from '../../../assets/images/google.png';
import linkedinLogo from '../../../assets/images/linkedin.png';
import SocialLoginButton from '../../../component/Controls/custom/buttons/socialLoginButton';
import { useGoogleLogin } from '@react-oauth/google';
import { LinkedIn, useLinkedIn } from 'react-linkedin-login-oauth2';
import ImageLoader from '../../../component/Controls/loaders/imageLoader';


const Register = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const navigate = useNavigate();

    const REGISTER_FORM = "RegisterForm";

    const registerStatus = useSelector(selectRegisterStatus);
    const registerError = useSelector(selectRegisterError);
    const socialLoginStatus = useSelector(selectSocialLoginStatus);
    const socialLoginError = useSelector(selectSocialLoginError);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [numberOfEmployees, setNumberOfEmployees] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [rfSubmitted, setRFSubmitted] = useState(false);
    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [passwordLengthInvalid, setPasswordLengthInvalid] = useState(false);

    const registerDetail = registerDetailsEN;

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);  // Parse query params
        
        const _fullname = queryParams.get("fullname");
        const _email = queryParams.get("email");

        if (_fullname) {
            const nameParts = _fullname.split(" ");
            setFirstName(nameParts[0]);
            setLastName(nameParts.slice(1).join(" "));
        }

        if (_email) {
            setEmail(_email);
        }
    }, [window.location.search]);

    const onRegisterClicked = (e) => {
        e.preventDefault();

        setPasswordMismatch(false);
        setPasswordLengthInvalid(false);
        if (isFormValid(REGISTER_FORM)) {
            if (password.length >= 8) {
                if (password === confirmPassword) {
                    setPasswordMismatch(false);
                    accountService.register({
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        phoneNumber: phoneNumber,
                        password: password,
                        confirmPassword: confirmPassword,

                        companyName: companyName,

                    });
                }
                else {
                    setPasswordMismatch(true);
                }
            }
            else {
                setPasswordLengthInvalid(true);
            }
        }
        else {
            setRFSubmitted(true);
        }
    }

    useEffect(() => {
        if (registerStatus === fetchStatus.SUCCEEDED) {
            if (accountService.isAuthenticated()) {
                dispatch(updateLoginStatus());
                // navigate("/submission");          // Need Comment
                redirectHubSpotMarketPlace();  // Need unComment
            }
        }
    }, [registerStatus, dispatch, history]);

    useEffect(() => {
        if (registerStatus === fetchStatus.FAILED) {
            //   handlerError(registerError);
            if (registerError === "Email address is not verified") {
                // navigate("/submission");
            }
        }
    }, [registerStatus]);

    //Socal Login
    const _getSocialLogin = async (token, provider) => {
        let dataObj = {
            token: token,
            provider: provider,
        }
        await dispatch(socialLogin(dataObj));
    };

    useEffect(() => {
        if (socialLoginStatus === fetchStatus.SUCCEEDED) {
            if (accountService.isAuthenticated()) {
                dispatch(updateSocialLoginStatus());
                dispatch(updateProfileStatus());

                // navigate("/submission"); // Need unComment
                // return;                  // Need unComment

                if (accountService.isRegistrationComplete() === false) {
                    navigate("/profile-details");
                }
                else if (accountService.isCompanySetup() === false) {
                    redirectHubSpotMarketPlace();
                } else {
                    navigate("/dashboard");
                }
            }
        }
    }, [socialLoginStatus]);

    useEffect(() => {
        if (socialLoginStatus === fetchStatus.FAILED) {
            if (socialLoginError === "Email address is not verified") {
                // navigate("/submission");
            }
        }
    }, [socialLoginStatus]);

    const signInWithGoogleClicked = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            try {
                if (codeResponse && codeResponse.access_token) {
                    _getSocialLogin(codeResponse.access_token, "google");
                }
            } catch (error) {
                console.error("Error during Google login:", error);
            }
        },
        onError: (error) => console.error("Google Login Failed:", error),
    });

    const redirectUrl = linkedInOptions.REDIRECT_URL;
    const linkedInClientId = linkedInOptions.CLIENT_ID;
    const scope = "openid profile email";

    const { linkedInLogin } = useLinkedIn({
        clientId: linkedInClientId,
        scope: scope,
        redirectUri: redirectUrl,
        onSuccess: (code) => {
            try {
                if (code) {
                    _getSocialLogin(code, "linkedin");
                }
            } catch (error) {
                console.error("Error during LinkedIn login:", error);
            }

        },
        onError: (error) => {
        }
    });


    return (
        <>
            {(registerStatus === fetchStatus.LOADING ||
                socialLoginStatus === fetchStatus.LOADING
            ) && <div className="app-container-loader">
                    <ImageLoader />
                </div>
            }

            <div id="page" className="page font--jakarta">
                <div className="signup bg--scroll login-section division">
                    <div className="container">
                        <div className="row justify-content-center">

                            <div className="col-lg-12">
                                <div className="register-page-wrapper r-16 bg--fixed">
                                    <div className="row">

                                        <div className="col-md-8 register-page-white">
                                            <div className="register-page-form">
                                                <div name="signupform" className="row sign-up-form">
                                                    <div className="col-md-12 mb-4">
                                                        <SocialLoginButton
                                                            text={`Sign up with Google`}
                                                            imgSrc={goolgeLogo}
                                                            buttonclasses="btn btn-google ico-left"
                                                            onClick={signInWithGoogleClicked}
                                                            loading={socialLoginStatus === fetchStatus.LOADING}
                                                        />
                                                    </div>
                                                    {/* <div className="col-md-12">
                                                        <SocialLoginButton
                                                            text={`Sign up with Linkedin`}
                                                            imgSrc={linkedinLogo}
                                                            buttonclasses="btn btn-google ico-left"
                                                            onClick={linkedInLogin}
                                                            loading={socialLoginStatus === fetchStatus.LOADING}
                                                        />
                                                    </div> */}


                                                    <div className="col-md-12 text-center">
                                                        <div className="separator-line">Or, sign up with your email</div>
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                        <p className="p-sm input-header">{registerDetail.FirstName}</p>
                                                        <ShortText
                                                            id="txtFirstName"
                                                            name="txtFirstName"
                                                            ShortTextClasses="form-control"
                                                            placeholder={registerDetail.FirstName}
                                                            required={true}
                                                            errorMessage={registerDetail.FirstNameError}
                                                            form={REGISTER_FORM}
                                                            submited={rfSubmitted}
                                                            value={firstName}
                                                            onChange={(e) => setFirstName(e.target.value)} />
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                        <p className="p-sm input-header">{registerDetail.LastName}</p>
                                                        <ShortText
                                                            id="txtLastName"
                                                            name="txtLastName"
                                                            ShortTextClasses="form-control"
                                                            placeholder={registerDetail.LastName}
                                                            required={true}
                                                            errorMessage={registerDetail.LastNameError}
                                                            form={REGISTER_FORM}
                                                            submited={rfSubmitted}
                                                            value={lastName}
                                                            onChange={(e) => setLastName(e.target.value)} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <p className="p-sm input-header">{registerDetail.PhoneNumber}</p>
                                                        <PhoneText
                                                            id="txtPhoneNumber"
                                                            name="txtPhoneNumber"
                                                            PhoneTextClasses="form-control"
                                                            placeholder={registerDetail.PhoneNumber}
                                                            required={true}
                                                            errorMessage={registerDetail.PhoneNumberError}
                                                            form={REGISTER_FORM}
                                                            submited={rfSubmitted}
                                                            value={phoneNumber}
                                                            onChange={(e) => setPhoneNumber(e.target.value)} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <p className="p-sm input-header">{registerDetail.Email}</p>
                                                        <EmailText
                                                            id="txtEmail"
                                                            name="txtEmail"
                                                            EmailTextClasses="form-control"
                                                            placeholder={registerDetail.Email}
                                                            required={true}
                                                            errorMessage={registerDetail.EmailError}
                                                            form={REGISTER_FORM}
                                                            submited={rfSubmitted}
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <p className="p-sm input-header">{registerDetail.Password}</p>
                                                        <PasswordText
                                                            id="txtPassword"
                                                            name="txtPassword"
                                                            PasswordTextClasses="form-control"
                                                            placeholder={registerDetail.Password}
                                                            required={true}
                                                            errorMessage={registerDetail.PasswordError}
                                                            form={REGISTER_FORM}
                                                            submited={rfSubmitted}
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)} />
                                                        {passwordLengthInvalid && <span className="error-label">
                                                            <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                                                            {registerDetail.PasswordLengthError}
                                                        </span>}
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <p className="p-sm input-header">{registerDetail.ConfirmPassword}</p>
                                                        <PasswordText
                                                            id="txtConfirmPassword"
                                                            name="txtConfirmPassword"
                                                            PasswordTextClasses="form-control"
                                                            placeholder={registerDetail.ConfirmPassword}
                                                            required={true}
                                                            errorMessage={registerDetail.ConfirmPasswordError}
                                                            form={REGISTER_FORM}
                                                            submited={rfSubmitted}
                                                            value={confirmPassword}
                                                            onChange={(e) => setConfirmPassword(e.target.value)} />
                                                        {passwordMismatch && <span className="error-label">
                                                            <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                                                            {registerDetail.PasswordMismatchError}
                                                        </span>}
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <p className="p-sm input-header">{registerDetail.CompanyName}</p>
                                                        <ShortText
                                                            id="txtCompanyName"
                                                            name="txtCompanyName"
                                                            ShortTextClasses="form-control placeholder-text"
                                                            placeholder={registerDetail.CompanyName}
                                                            required={true}
                                                            errorMessage={registerDetail.CompanyNameError}
                                                            form={REGISTER_FORM}
                                                            submited={rfSubmitted}
                                                            value={companyName}
                                                            onChange={(e) => setCompanyName(e.target.value)} />
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-data">
                                                            <span>
                                                                By clicking “Create Account”, you agree to our <a href="https://getfollowups.com/terms-of-service" target="_blank">Terms of Service & Privacy Policy</a>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <button type="submit" className="btn btn--theme hover--theme submit" onClick={(e) => onRegisterClicked(e)}>Create Account</button>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <p className="create-account text-center">
                                                            Already have an account? <Link to="/login" className="color--theme">Log in</Link>
                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-4 register-page-img">
                                            <div className="register-page-txt color--white">
                                                <span className="section-id">Start for free</span>
                                                <h3 className="s-48 w-700">Create an account</h3>
                                                <p className="p-md mt-25">
                                                    <ul>
                                                        <li>Improve the quality of your prospecting data</li>
                                                        <li>Connect with more prospects</li>
                                                        <li>Schedule more appointments</li>
                                                        <li>Close deals faster</li>
                                                    </ul>
                                                </p>
                                                <div className="register-page-copyright">
                                                    <p className="p-sm">© 2025 FollowUps. <span>All Rights Reserved</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
};

export default Register; 