export const ErrorCode = {
    EMAIL_IN_USE: 100,
    UNKNOWN_SERVER_ERROR: 101,
    INVALID_TOKEN: 102,
    INVALID_LOGIN_ATTEMPT: 103,
    UNAUTHORIZED: 104,
    INVALID_RESET_TOKEN: 105
};

export const USER_KEY = "THSBGDYDPKDH2233GTF";

export const Role = {
    ADMIN: "Admin",
    APPLICANT: "Applicant"
};

export const ForPage = {
    ADMIN: "Admin",
    USER: "User"
}

export const Operation = {
    CREATED: 0,
    UPDATED: 1,
    DELETED: 2
}


export const AccountPlan = {
    0: "Free",
    1: "Basic",
    2: "Standard",
    3: "Enterprise",
}

export const callDispositionOptions = [
    "Add to Do Not Call List",
    "Appointment Scheduled",
    "Busy",
    "Call Back Later",
    "Connected",
    "Discovery Call Completed",
    "Disqualified",
    "Follow Up",
    "Left Live Message",
    "Left Voicemail",
    "Meeting Scheduled",
    "No Answer",
    "No Longer With Company",
    "Not Interested",
    "Wrong Contact",
    "Wrong Number"
];

export const callDispositionOptionsHelperText = [
    { value: "Add to Do Not Call List", helperText: "The prospect requested removal from the call list." },
    { value: "Appointment Scheduled", helperText: "A formal appointment was booked." },
    { value: "Busy", helperText: "The prospect picked up but was too busy to talk." },
    { value: "Call Back Later", helperText: "The prospect requested a callback at a later time." },
    { value: "Connected", helperText: "The prospect answered and engaged in conversation." },
    { value: "Discovery Call Completed", helperText: "The prospect was fully qualified through discovery." },
    { value: "Disqualified", helperText: "The lead does not meet the qualification criteria." },
    { value: "Follow Up", helperText: "A follow-up call or email is required." },
    { value: "Left Live Message", helperText: "A message was left with the prospect." },
    { value: "Left Voicemail", helperText: "A voicemail was left requesting a callback." },
    { value: "Meeting Scheduled", helperText: "A follow-up meeting or demo was successfully scheduled." },
    { value: "No Answer", helperText: "The call was placed, but the prospect did not pick up, and no voicemail was left." },
    { value: "No Longer With Company", helperText: "The contact is no longer employed at the company." },
    { value: "Not Interested", helperText: "The prospect was reached but expressed no interest." },
    { value: "Wrong Contact", helperText: "The person reached was not the intended prospect." },
    { value: "Wrong Number", helperText: "The number dialed was incorrect or outdated." }
  ];

export const leadStatusOptions = [
    "New",
    "Attempting to Contact",
    "Connected",
    "Not Interested",
    // "Meeting",
    "Meeting Scheduled",
    "Meeting Held",
    "Meeting Declined",
    "No Show",
    "Nurture",
    // "Nurture (not now)",
    // "Nurture (open deal)",
    "Open Deal",
    "Customer",
    // "Customer - Onboarding",
    // "Customer - Engaged",
    // "Customer - Passive",
    // "Customer - Paused",
    // "Customer - Unaware",
    // "Customer - Not Interested",
    "Closed Lost",
    "Changed Role/Company",
    "Disqualified",
    "Do Not Call",
    "Open",
    "In Progress",
    "Other",
    "Wrong Number",
    "Email Bounced",
    "Email Opt Out"
];

export const leadStatusOptionsHelperText = [
    { value: "Attempting to Contact", helperText: "The sales team has tried to reach out but has not yet connected." },
    { value: "Changed Role/Company", helperText: "Lead has changed jobs or moved to a new company." },
    { value: "Closed Lost", helperText: "The deal was lost, and the lead did not convert." },
    { value: "Connected", helperText: "The lead has responded, and there has been a conversation." },
    { value: "Customer", helperText: "Lead has converted into a paying customer." },
    { value: "Disqualified", helperText: "The lead is not a good fit for your product or service." },
    { value: "Do Not Call", helperText: "Lead has requested not to be contacted via phone." },
    { value: "Email Bounced", helperText: "Email to the lead was undeliverable." },
    { value: "Email Opt Out", helperText: "Lead has opted out of receiving marketing emails." },
    { value: "In Progress", helperText: "A salesperson is actively engaging with the lead." },
    { value: "Meeting Declined", helperText: "Lead declined or canceled the scheduled meeting." },
    { value: "Meeting Held", helperText: "The scheduled meeting was successfully completed." },
    { value: "Meeting Scheduled", helperText: "A meeting has been scheduled with the lead." },
    { value: "New", helperText: "A fresh lead that has not been contacted yet." },
    { value: "No Show", helperText: "Lead did not attend the scheduled meeting." },
    { value: "Not Interested", helperText: "Lead has indicated no interest in the offering." },
    { value: "Nurture", helperText: "Lead is not ready to buy but should be engaged over time." },
    { value: "Open", helperText: "The lead has been identified and is in the process of being worked on." },
    { value: "Open Deal", helperText: "The lead has shown interest, and a deal has been created." },
    { value: "Other", helperText: "Lead status does not fit into existing categories." },
    { value: "Wrong Number", helperText: "The provided phone number is incorrect or invalid." }
];

export const followupsStatusesData = {
    Bogus: 'Bogus',
    ChangeCommunicationChannel: 'Change Communication Channel',
    Disqualified: 'Disqualified',
    Enrich: 'Enrich',
    Nurture: 'Nurture',
    Prospect: 'Prospect',
    '1:1SalesApproach': '1:1 Sales Approach'
};

