import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import './App.css';
import Login from './features/accounts/screens/Login';
import Register from './features/accounts/screens/Register';
import Callback from './features/accounts/screens/Callback';
import ProtectedRoute from './routes/ProtectedRoute';
import Home from './features/home/Home';
import UserFollowUpProcess from './features/settings/screens/UserFollowUpProcess';
import ThankYou from './features/settings/screens/ThankYou';
import Dashboard from './features/dashboard/screens/dashboard';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import './assets/css/customTheme.css';
import LayoutRoute from './routes/LayoutRoute';
import UserFollowUp from './features/settings/screens/UserFollowUp';
import ProfileDetails from './features/accounts/screens/ProfileDetails';
import Contacts from './features/contacts/screens/contacts';
import Vocabulary from './features/settings/screens/components/Vocabulary';
import Setting from './features/settings/screens/Setting';
import PortalSelection from './features/accounts/screens/PortalSelection';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Submission from './features/settings/screens/Submission';
import HubspotMeetingModalPopUp from './component/Controls/modals/hubspotMeetingModalPopUp';
import CongratulationsModalPopUp from './component/Controls/modals/congratulationsModalPopUp';

function App() {
  return (
    <>
      {<ToastContainer autoClose={5000}/>}
      <HubspotMeetingModalPopUp />
      <CongratulationsModalPopUp />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/connect" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/submission" element={<Submission />} />
          <Route exact path="/linkedin/callback" element={<LinkedInCallback />} />
          <Route element={<ProtectedRoute redirectTo="/login" />}>
            <Route path="/callback" element={<Callback />} />
            <Route path="/profile-details" element={<ProfileDetails />} />
            <Route path="/settings/userFollowUpProcess" element={<UserFollowUpProcess />} />
            <Route path="/portal-selection" element={<PortalSelection />} />
          </Route>
          <Route element={<LayoutRoute redirectTo="/login" />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/settings" element={<Setting />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
