import React from 'react';
import Logo from '../../../component/logo/logo';
import meteorRainImg from './../../../assets/images/clipart/meteor-rain.gif';


function ThankYou() {
  return (
    <>
      {/* <div className="container text-center new-card-ui p-5">
        <div className="row">
          <div className="col-auto mb-5">
            <Logo />
          </div>
          <div className="col-12">
            <div className="alert alert-success" role="alert">
              <h4 className="alert-heading">Thank You!</h4>
              <p>Your submission has been received successfully.</p>
              <hr />
              <p className="mb-0">We appreciate your feedback and will get back to you shortly.</p>
            </div>
            <a href="https://app.hubspot.com" className="btn btn-primary mt-3">
              Go Back To Hubspot
            </a>
          </div>
        </div>
      </div> */}

      <div className="container justify-content-center col-12 col-md-6 congratulations">
        <div className="animate__animated animate__fadeIn">
          <div className="card card-wizard">
            <div className="card-body">
              <div className="row p-2">
                <div className="col-md-12 justify-content-center">
                  <center>
                    <img src={meteorRainImg} alt="no photo" style={{ width: "20%" }} />
                    <div className="animate__animated animate__zoomIn">
                      <h3 className="pt-5">
                        Congratulations</h3>
                    </div>
                  </center>
                </div>
              </div>
              <div className="row p-2">
                <div className="col-md-12">
                  <center>
                    <div className="animate__animated animate__headShake">
                      <p>Now you are part of FollowUps. We appreciate your involvement and will get back to you shortly.</p>
                    </div>
                    <a href="https://app.hubspot.com" className="btn btn-primary mt-3">
                      Go Back To Hubspot
                    </a>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ThankYou;
