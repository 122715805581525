import React, { useState, useEffect } from "react";
import "./css/confettiEffect.css";

const ConfettiEffect = () => {
  const [confettiElements, setConfettiElements] = useState([]);

  useEffect(() => {
      generateConfetti();
    return () => setConfettiElements([]); // Cleanup confetti on unmount
  }, []);

  // Function to generate confetti items
  const generateConfetti = () => {
    const colors = ["#3b5692", "#f9c70b", "#00abed", "#ea6747"];
    const height = 6.6;
    const width = 6.6;
    let confettiItems = [];

    for (let i = 0; i < 100; i++) {
      const scale = Math.random() * 1.75 + 1;
      const rotate = Math.random() * 360;
      const left = Math.random() * 100 + "vw";
      const animationDuration = Math.random() + 2 + "s";
      const animationDelay = Math.random() * 2 + "s";
      const color = colors[Math.floor(Math.random() * colors.length)];

      confettiItems.push(
        <svg
          key={i}
          className="confetti-item"
          width={width * scale}
          height={height * scale}
          viewBox={`0 0 ${width} ${height}`}
          style={{
            animation: `${animationDuration} ${animationDelay} confetti-fall ease-in both`,
            color: color,
            left: left,
          }}
        >
          <use transform={`rotate(${rotate}, ${width / 2}, ${height / 2})`} href="#svg-confetti" />
        </svg>
      );
    }

    setConfettiElements(confettiItems);
  };

  return (
    <div>
      {/* SVG Symbol for Confetti */}
      <svg className="dn" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <symbol id="svg-confetti">
            <path fill="currentColor" d="M-.017 6.91L4.035.012l2.587 1.52L2.57 8.43z"></path>
          </symbol>
        </defs>
      </svg>

      {/* Button to Trigger Confetti */}
      {/* <button type="button" className="btn btn-primary" onClick={generateConfetti}>
        🎉 Start Confetti 🎉
      </button> */}

      {/* Confetti Container */}
      <div className="confetti">{confettiElements}</div>
    </div>
  );
};

export default ConfettiEffect;
