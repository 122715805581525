import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateLayout } from '../../../component/layouts/reducerSlices/layoutSlice';
import SearchBar from '../../../component/Controls/searchBars/searchbar';
import { getContacts, selectContacts, selectCurrentPage, selectStatus, selectTotalItems, updateCurrentPage, updateIsFiltered, updateStatus } from '../reducerSlices/contactsSlice';
import { fetchStatus } from '../../../api/client';
import Collection from '../../../component/Controls/paginators/collection';
import PageContainer from '../../../component/Controls/paginators/paginator/pageContainer';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { TextRow } from 'react-placeholder/lib/placeholders';
import EllipsisLoader from '../../../component/Controls/loaders/ellipsisLoader';
import ContactCard from '../../../component/Controls/cards/ContactCard';
import { _getNoDataText } from '../../../common/common';


function Contacts() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isFiltered, setIsFiltered] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [contactRecords, setContactRecords] = useState(new Collection());
    const [contactRecordsCard, setContactRecordsCard] = useState(new Collection());
    const [dummyCards, setDummyCards] = useState([]);
    const [whereClauseFilter, setWhereClauseFilter] = useState({ searchTerm: "", followups: "" });

    const totalPageItems = useSelector(selectTotalItems);
    const _contacts = useSelector(selectContacts);
    const currentPage = useSelector(selectCurrentPage);
    const contactsRequestStatus = useSelector(selectStatus);


    useEffect(() => {
        dispatch(updateLayout({
            page: 3,
            title: "Contacts"
        }));
    }, []);

    const getBadgeClass = (followup) => {
        switch (followup) {
            case "Enrich":
                return "badge-enrich";
            case "1:1 Sales Approach":
                return "badge-sales";
            case "Nurture":
                return "badge-nurture";
            case "Change Communication Channel":
                return "badge-change";
            case "Prospect":
                return "badge-prospect";
            case "Disqualified":
                return "badge-disqualified";
            case "Bogus":
                return "badge-bogus";
            default:
                // return "badge-bogus";
                return "";
        }
    };





    const _loadcontacts = async (page, offset, limit, whereClause = {}, sort = {}) => {
        await dispatch(getContacts({
            page: page,
            model: {
                // payload: {
                id: "",
                data: JSON.stringify({
                    offset: offset,
                    limit: limit,
                    where: whereClause,
                    sort: sort
                })
                // }
            }
        }));
    }


    useEffect(() => {
        if (contactsRequestStatus === fetchStatus.IDLE) {
            dispatch(updateIsFiltered());
            _loadcontacts(0, 0, 10, whereClauseFilter, {});
        }
    }, [contactsRequestStatus]);

    let _dummyCards = [];
    let _contactRecords = new Collection();
    let _contactCardRecords = new Collection();


    useEffect(() => {
        if (contactsRequestStatus === fetchStatus.LOADING) {
            for (let i = 0; i < 10; i++) {
                _dummyCards.push(
                    <>
                        {/* <tr>
                            <td colspan="8">
                                <div className='loader-table'>
                                    <EllipsisLoader />
                                </div>

                            </td>
                        </tr> */}
                        <div className="contact-card">
                            <div className="contact-card-body d-block p-4">
                                <ReactPlaceholder showLoadingAnimation={true} type='media' ready={false} rows={2}>
                                </ReactPlaceholder>
                            </div>
                        </div>
                    </>
                );
            }
            setDummyCards(_dummyCards);
        }
    }, [contactsRequestStatus])

    const onClickContact = (portalId, id) => {
        const url = `https://app.hubspot.com/contacts/${portalId}/record/0-1/${id}/`;
        window.open(url, "_blank", "noopener,noreferrer");
    };

    useEffect(() => {
        if (contactsRequestStatus === fetchStatus.SUCCEEDED || contactsRequestStatus === fetchStatus.DONE) {
            // let _records = _contacts;


            let _records = _contacts.Items;

            if (_contacts.Items && _contacts.Items.length > 0) {
                for (let i = 0; i < _contacts.Items.length; i++) {
                    let item = _contacts.Items[i];
                    let contactCards = item.records.map((contactData, index) => (
                        <tr key={contactData.id}>
                            {/* <td>{contactData.properties.lastname + ' ' + contactData.properties.firstname}</td> */}
                            <td>
                                <a
                                    href={`https://app.hubspot.com/contacts/${contactData.portalId}/record/0-1/${contactData.id}/`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {contactData.properties.lastname + ' ' + contactData.properties.firstname}
                                </a>
                            </td>

                            <td>{contactData.properties.email}</td>
                            <td>{contactData.properties.phone}</td>
                            <td>{contactData.followups_fits_icp}</td>
                            <td>{contactData.followups_icp_reason}</td>
                            <td>
                                <span className={`badge ${getBadgeClass(contactData.followups)}`}>
                                    {contactData.followups}
                                </span>
                            </td>
                            <td>{contactData.followups_reason}</td>
                            <td>{contactData.followups_percentage}</td>
                        </tr>
                    ));
                    _contactRecords.Add(item.key, contactCards);


                    let contactCard = item.records.map((contactData, index) => (
                        <ContactCard
                            id={contactData.id}
                            // contactsLink={`https://app.hubspot.com/contacts/${contactData.portalId}/record/0-1/${contactData.id}/`}
                            firstname={contactData.properties.firstname}
                            lastname={contactData.properties.lastname}
                            fullName={contactData.properties.lastname + ' ' + contactData.properties.firstname}
                            email={contactData.properties.email ? contactData.properties.email : _getNoDataText()}
                            phone={contactData.properties.phone ? contactData.properties.phone : _getNoDataText()}
                            company={contactData.properties.company ? contactData.properties.company : _getNoDataText()}
                            followups={contactData.followups}
                            followups_reason={contactData.followups_reason}
                            followups_percentage={contactData.followups_percentage}
                            followups_fits_icp={contactData.followups_fits_icp}
                            followups_icp_reason={contactData.followups_icp_reason}
                            onClick={() => onClickContact(contactData.portalId, contactData.id)}
                        />
                    ));
                    _contactCardRecords.Add(item.key, contactCard);
                }

                setContactRecords(_contactRecords);
                setContactRecordsCard(_contactCardRecords);
                dispatch(updateStatus());
            }
            dispatch(updateStatus());

        }
    }, [contactsRequestStatus]);

    const tableHeaderRow = <tr>
        <th scope="col">Name</th>
        <th scope="col">Email</th>
        <th scope="col">Phone Number</th>
        <th scope="col">Fits ICP </th>
        <th scope="col">ICP Reason</th>
        <th scope="col">Followups</th>
        <th scope="col">Reason</th>
        <th scope="col">Matching Percentage</th>
    </tr>;

    const onLoadItems = async (p) => {
        await _loadcontacts(p, p * 10, 10, whereClauseFilter, {});
    };

    const onPageChanged = async (p, l) => {
        dispatch(updateCurrentPage(p));
        if (l) {
            await onLoadItems(p);
        }
    };

    const onCustomFiterClicked = (e, id, defaultFilter) => {
        e.preventDefault();
        let _whereClauseFilter = whereClauseFilter;
        _whereClauseFilter.followups = id;

        dispatch(updateIsFiltered());
        setWhereClauseFilter(_whereClauseFilter);
        _loadcontacts(0, 0, 10, whereClauseFilter, {});

    };

    const onSearchClicked = async (e, value) => {
        e.preventDefault();
        if (value) {
            //   let where = JSON.parse(JSON.stringify(whereClause));
            //   where.name = value;
            //   await dispatch(updateNameFilter(value));
            //   await dispatch(updateIsFiltered());
            //   _loadApplicants(0, 0, 10, where, sort);

            let _whereClauseFilter = whereClauseFilter;
            _whereClauseFilter.searchTerm = searchTerm;

            dispatch(updateIsFiltered());
            setWhereClauseFilter(_whereClauseFilter);
            _loadcontacts(0, 0, 10, whereClauseFilter, {});
        }
    };

    const onSearchCleared = async (e) => {
        e.preventDefault();
        // await dispatch(updateIsFiltered());
        // let where = JSON.parse(JSON.stringify(whereClause));
        // where.name = "";
        // await dispatch(updateNameFilter(""));
        // _loadApplicants(0, 0, 10, where, sort);

        let _whereClauseFilter = whereClauseFilter;
        _whereClauseFilter.searchTerm = "";

        dispatch(updateIsFiltered());
        setWhereClauseFilter(_whereClauseFilter);
        _loadcontacts(0, 0, 10, whereClauseFilter, {});
    };

    const onFilterClicked = async (e) => {
        e.preventDefault();
        // await dispatch(updateIsFiltered());
        // dispatch(updateFilter({
        //   id: "",
        //   name: "",
        //   whereClause: whereClause,
        //   defaultFilter: false
        // }));
        // _loadApplicants(0, 0, 10, whereClause, sort);
    };


    return (
        <>
            <div className="filter-bar p-3">
                <>
                    <button className={`c-btn mx-1 ${whereClauseFilter.followups === "" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "", true)}>All</button>
                    <button className={`c-btn mx-1 ${whereClauseFilter.followups === "Enrich" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "Enrich", true)}>Enrich</button>
                    <button className={`c-btn mx-1 ${whereClauseFilter.followups === "1:1 Sales Approach" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "1:1 Sales Approach", true)}>1:1 Sales Approach</button>
                    <button className={`c-btn mx-1 ${whereClauseFilter.followups === "Nurture" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "Nurture", true)}>Nurture</button>
                    <button className={`c-btn mx-1 ${whereClauseFilter.followups === "Change Communication Channel" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "Change Communication Channel", true)}>Change Communication Channel</button>
                    <button className={`c-btn mx-1 ${whereClauseFilter.followups === "Prospect" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "Prospect", true)}>Prospect</button>
                    <button className={`c-btn mx-1 ${whereClauseFilter.followups === "Disqualified" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "Disqualified", true)}>Disqualified</button>
                    <button className={`c-btn mx-1 ${whereClauseFilter.followups === "Bogus" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "Bogus", true)}>Bogus</button>
                </>
            </div>
            <div className="filter-bar p-3 pt-0">
                <div className="col-3">
                    <SearchBar
                        id="txtSearch"
                        name="txtSearch"
                        searchTerm={searchTerm}
                        onSearch={(e) => setSearchTerm(e.target.value)}
                        onClear={(e, value) => onSearchCleared(e)}
                        onSearchClicked={(e, value) => onSearchClicked(e, value)} />
                </div>
            </div>

            {/* <div className='default-container-wrapper p-3'>
                <div className="default-root-container detail-container p-5 mt-2">
                    <PageContainer
                        currentPage={currentPage}
                        totalPageItems={totalPageItems}
                        pageRecords={contactRecordsCard}
                        isItemsLoading={contactsRequestStatus}
                        itemsLoadingContent={dummyCards}
                        onPageChanged={(p, l) => onPageChanged(p, l)}
                        infinite={false}
                        pageRecordsCardClassName={'people-card'}
                        tableHeaderRow={tableHeaderRow}
                    />
                </div>
            </div> */}

            <PageContainer
                currentPage={currentPage}
                totalPageItems={totalPageItems}
                pageRecords={contactRecordsCard}
                isItemsLoading={contactsRequestStatus}
                itemsLoadingContent={dummyCards}
                // itemsLoadingContent={generateDummyCards()}
                onPageChanged={(p, l) => onPageChanged(p, l)}
                infinite={true}
                pageRecordsCardClassName={'contact-card'}
                tableHeaderRow={tableHeaderRow}
            />
        </>
    )
}

export default Contacts