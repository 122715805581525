import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    title: "",
    id: "",
    show: false
};

export const congratulationsModalPopUpSlice = createSlice({
    name: 'congratulationsModalPopUp',
    initialState,
    reducers: {
        showCongratulationsModal: (state, action) => {
            const { title, id } = action.payload;
            state.title = title;
            state.id = id;
            state.show = true;
        },
        closeModal: state => {
            state.show = false;
        }
    }
});

export const { showCongratulationsModal, closeModal } = congratulationsModalPopUpSlice.actions;

export default congratulationsModalPopUpSlice.reducer

export const selectModalPopUpDetail = state => {
    return {
        title: state.congratulationsModalPopUp.title,
        id: state.congratulationsModalPopUp.id,
        show: state.congratulationsModalPopUp.show
    }
}