import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardCounts, selectDashboardCounts, selectDashboardCountsStatus, selectIsFirstTimeDashboardVisit, updateIsFirstTimeDashboardVisit } from '../reducerSlices/dashboardSlice';
import { fetchStatus } from '../../../api/client';
import DashboardCard from '../../../component/Controls/cards/dashboardCard';
import { updateLayout } from '../../../component/layouts/reducerSlices/layoutSlice';
import CustomVerticalBarChart from './component/CustomVerticalBarChart';
import { accountService } from '../../accounts/services/accountService';
import ConfettiEffect from '../../../component/Animation/ConfettiEffect';
import { showCongratulationsModal } from '../../../component/Controls/modals/reducerSlices/congratulationsModalPopUpSlice';


function Dashboard() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateLayout({
            page: 1,
            title: "Dashboard"
        }));
    }, []);

    const [isAnimationVisible, setIsAnimationVisible] = useState(false);

    const isFirstTimeDashboardVisit = useSelector(selectIsFirstTimeDashboardVisit);
    const dashboardCounts = useSelector(selectDashboardCounts);
    const dashboardCountsStatus = useSelector(selectDashboardCountsStatus);

    const _loadDashboardCounts = async () => {
        await dispatch(getDashboardCounts({}));

    }

    useEffect(() => {
        if (dashboardCountsStatus === fetchStatus.IDLE) {
            _loadDashboardCounts();
        }
    }, [dashboardCountsStatus]);

    const _showCongratulationsModal = () => {
        dispatch(showCongratulationsModal({
            title: <></>,
            id: ""
        }));
    }

    useEffect(() => {
        // if (accountService.isLicensed() === false) {
        if (isFirstTimeDashboardVisit === true) {
            dispatch(updateIsFirstTimeDashboardVisit(false));
            _showCongratulationsModal();

            setIsAnimationVisible(true);

            const timer = setTimeout(() => {
                setIsAnimationVisible(false);
            }, 10000);

            return () => clearTimeout(timer);
        }
        // _showCongratulationsModal();
    }, []);



    return (
        <>
            <div className="default-container-wrapper py-3 px-2">
                <div className="row mt-2">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3">
                        <DashboardCard
                            title="1:1 Sales Approach"
                            cardBackgroundClass="followup-1"
                            iconBackgroundClass="bg-success"
                            number={dashboardCounts ? dashboardCounts["1:1 Sales Approach"] : "-1"}
                            faIcon="fa-handshake"
                            isItemsLoading={dashboardCountsStatus} />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3">
                        <DashboardCard
                            title="Nurture"
                            cardBackgroundClass="followup-nurture"
                            iconBackgroundClass="bg-info"
                            number={dashboardCounts ? dashboardCounts["Nurture"] : "-1"}
                            faIcon="fa-users"
                            isItemsLoading={dashboardCountsStatus} />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3">
                        <DashboardCard
                            title="Change Communication Channel"
                            cardBackgroundClass="followup-channel"
                            iconBackgroundClass="bg-primary"
                            number={dashboardCounts ? dashboardCounts["Change Communication Channel"] : "-1"}
                            faIcon="fa-exchange-alt"
                            isItemsLoading={dashboardCountsStatus} />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3">
                        <DashboardCard
                            title="Enrich"
                            cardBackgroundClass="followup-enrich"
                            iconBackgroundClass="bg-warning"
                            number={dashboardCounts ? dashboardCounts["Enrich"] : "-1"}
                            faIcon="fa-lightbulb"
                            isItemsLoading={dashboardCountsStatus} />
                    </div>
                </div>
                <div className="row mt-2">

                    <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3">
                        <DashboardCard
                            title="Prospect"
                            cardBackgroundClass="cold-prospect"
                            iconBackgroundClass="bg-blue"
                            number={dashboardCounts ? dashboardCounts["Prospect"] : "-1"}
                            faIcon="fa-bullhorn"
                            isItemsLoading={dashboardCountsStatus} />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3">
                        <DashboardCard
                            title="Disqualified"
                            cardBackgroundClass="cold-disqualified"
                            iconBackgroundClass="bg-dark"
                            number={dashboardCounts ? dashboardCounts["Disqualified"] : "-1"}
                            faIcon="fa-times-circle"
                            isItemsLoading={dashboardCountsStatus} />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3">
                        <DashboardCard
                            title="Bogus"
                            cardBackgroundClass="cold-bogus"
                            iconBackgroundClass="bg-danger"
                            number={dashboardCounts ? dashboardCounts["Bogus"] : "-1"}
                            faIcon="fa-ban"
                            isItemsLoading={dashboardCountsStatus} />
                    </div>
                </div>

                <div className="row mt-3" >
                    <div className=" col-md-12 col-lg-8 col-xl-8 col-xxl-6 mb-4">
                        <div className="dashboard-container">
                            <div className="dashboard-body">
                                {/* {!registrationCount ?  */}
                                {/* <Loader type="spinner-circle" bgColor={"#222fb9"} title={""} color={'#222fb9'} size={20} /> : <></>} */}
                                <div>
                                    <CustomVerticalBarChart
                                        // actBtn={actBtnApplicantRegistration}
                                        // showLocation='false'
                                        // startDate={Moment(startApplicantRegistration).format('YYYY-MM-DD')}
                                        // endDate={Moment(endApplicantRegistration).format('YYYY-MM-DD')}
                                        // locationOptionsValue={locationOptionsValue}
                                        // onLocationChange={(e) => setLocationApplicantRegistration(e.target.value)}

                                        // onWeekClicked={(e) => onWeekClickedApplicantRegistration(e)}
                                        // onMonthClicked={(e) => onMonthClickedApplicantRegistration(e)}
                                        // onTodayClicked={(e) => onTodayClickedApplicantRegistration(e)}

                                        // onFilter={(e) => onApplicantRegistrationDateChange(e)}

                                        title="Contacts Followups"
                                        legendChart="Followups"
                                        // labelsChart={registrationCount && JSON.parse(registrationCount[0].labelsChart)}
                                        // valuesChart={registrationCount && registrationCount[0].valuesChart.replace('[', '').replace(']', '').split(',')}
                                        // labelsChart={["COMPTON","ONTARIO","BUENA PARK","VAN NUYS","CITY OF INDUSTRY","CORONA","BELL","TORRANCE","BELLFLOWER","NORWALK","Alhambra","San Diego","San Jose","Philippines","Taiwan","testing new location"]}
                                        // valuesChart={[3,0,16,4,0,0,0,1,0,0,1,1,1,1,0,0]}
                                        labelsChart={dashboardCounts && Object.keys(dashboardCounts)}
                                        valuesChart={dashboardCounts && Object.values(dashboardCounts)}
                                        isItemsLoading={dashboardCountsStatus}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
            {isAnimationVisible &&
                <ConfettiEffect />
            }
            {/* <ConfettiEffect /> */}

        </>
    )
}

export default Dashboard