import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    title: "",
    id: "",
    show: false
};

export const hubspotMeetingModalPopUpSlice = createSlice({
    name: 'hubspotMeetingModalPopUp',
    initialState,
    reducers: {
        showHubspotMeetingModal: (state, action) => {
            const { title, id } = action.payload;
            state.title = title;
            state.id = id;
            state.show = true;
        },
        closeModal: state => {
            state.show = false;
        }
    }
});

export const { showHubspotMeetingModal, closeModal } = hubspotMeetingModalPopUpSlice.actions;

export default hubspotMeetingModalPopUpSlice.reducer

export const selectModalPopUpDetail = state => {
    return {
        title: state.hubspotMeetingModalPopUp.title,
        id: state.hubspotMeetingModalPopUp.id,
        show: state.hubspotMeetingModalPopUp.show
    }
}