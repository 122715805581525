import {
    createSlice,
    createAsyncThunk
}
    from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON } from '../../../utilities/utilityFunctions';

const initialState = {
    single: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: undefined
    },
    modification: {
        status: fetchStatus.IDLE,
        error: null
    },
};

export const getSettingICP = createAsyncThunk('settingICP/getSettingICP', async (settingICPModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Process/getSettingICP`, settingICPModel, getAxiosRequestConfig());
        const data = response.data ? response.data.Data : undefined;
        let settingICP = undefined;
        if (data && isJSON(data)) {
            settingICP = JSON.parse(data);
        }
        return {
            settingICP: settingICP,
            success: response.data.Success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const updateSettingICP = createAsyncThunk('settingICP/updateSettingICP', async (leadModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Process/updateSettingICP`, leadModel, getAxiosRequestConfig());
        const data = response.data ? response.data.Data : undefined;
        let settingICP = undefined;
        if (data && isJSON(data)) {
            settingICP = JSON.parse(data);
        }
        return {
            settingICP: settingICP,
            success: response.data.Success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const settingICPSlice = createSlice({
    name: 'settingICP',
    initialState,
    reducers: {
        updateSingleStatus: (state) => {
            state.single.status = fetchStatus.IDLE;
        },
        updateModificationStatus: (state) => {
            state.modification.status = fetchStatus.IDLE;
        },
        updateSingleData: (state, _data) => {
            if (_data.payload){
                state.single.data = {...state.single.data, ..._data.payload}
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSettingICP.pending, (state, action) => {
            state.single.status = fetchStatus.LOADING;
        })
            .addCase(getSettingICP.fulfilled, (state, action) => {
                state.single.data = action.payload.settingICP;
                state.single.status = fetchStatus.SUCCEEDED;
                toaster.success(action.payload.success);
            })
            .addCase(getSettingICP.rejected, (state, action) => {
                state.single.status = fetchStatus.FAILED;
                state.single.error = action.error.message;
                toaster.error(action.payload ? action.payload.Error : "");
            }).addCase(updateSettingICP.pending, (state, action) => {
                state.modification.status = fetchStatus.LOADING;
            }).addCase(updateSettingICP.fulfilled, (state, action) => {
                state.modification.status = fetchStatus.SUCCEEDED;
                toaster.success(action.payload.success);
            }).addCase(updateSettingICP.rejected, (state, action) => {
                state.modification.status = fetchStatus.FAILED;
                state.modification.error = action.error.message;
                toaster.error(action.payload ? action.payload.error : "");
            });
    }
});

export const {
    updateSingleStatus, updateModificationStatus, updateSingleData
} = settingICPSlice.actions;

export default settingICPSlice.reducer

export const selectSettingICP = (state) => {
    return state.settingICP.single ? state.settingICP.single.data : undefined;
}
export const selectSettingICPStatus = state => state.settingICP.single.status;
export const selectSettingICPError = state => state.settingICP.single.error;

export const selectModificationStatus = state => state.settingICP.modification.status;
export const selectModificationError = state => state.settingICP.modification.error;