import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLayout } from '../../../../component/layouts/reducerSlices/layoutSlice';
import { getSettingLeadStatus, selectModificationStatus, selectSettingLeadStatus, selectSettingLeadStatusStatus, updateModificationStatus, updateSettingLeadStatus, updateSingleData } from '../../reducerSlices/settingLeadStatusSlice';
import { fetchStatus } from '../../../../api/client';
import Selector from '../../../../component/Controls/custom/selectors/selector';
import { leadStatusOptions, leadStatusOptionsHelperText } from '../../../../common/constants';
import { isFormValid } from '../../../../component/Controls/services/formService';
import ImageLoader from '../../../../component/Controls/loaders/imageLoader';
import SelectorHelperText from '../../../../component/Controls/custom/selectors/selectorHelperText';


function SettingLeadStatus() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateLayout({
            page: 2,
            title: "Lead Status"
        }));
    }, []);

    const SLS_FORM = "SLSForm";
    const [sLSFSubmitted, setSLSFSubmitted] = useState(false);

    // const [properties, setProperties] = useState({});
    const [leadStatus, setLeadStatus] = useState([]);
    // const [selectedLeadStatus, setSelectedLeadStatus] = useState([]);


    const settingLeadStatusRequestStatus = useSelector(selectSettingLeadStatusStatus);
    const settingLeadStatus = useSelector(selectSettingLeadStatus);
    const settingLeadStatusModificationStatus = useSelector(selectModificationStatus);

    const _leadStatusOptionsHelperText = [
        { value: "", helperText: " -- Select -- " },
        ...leadStatusOptionsHelperText
    ];

    const _loadSettingLeadStatus = async () => {
        await dispatch(getSettingLeadStatus({}));
    }

    useEffect(() => {
        if (settingLeadStatusRequestStatus === fetchStatus.IDLE) {
            _loadSettingLeadStatus();
        }
    }, [settingLeadStatusRequestStatus]);

    useEffect(() => {
        if (settingLeadStatusRequestStatus === fetchStatus.SUCCEEDED) {
            let itemsLeadStatus = settingLeadStatus["itemsLeadStatus"];
            if (itemsLeadStatus) {
                setLeadStatus(itemsLeadStatus);
            } else {
                setLeadStatus([]);
            }
        }
    }, [settingLeadStatusRequestStatus]);

    useEffect(() => {
        if (settingLeadStatusModificationStatus === fetchStatus.SUCCEEDED) {
            dispatch(updateSingleData({ itemsLeadStatus: leadStatus }))
            dispatch(updateModificationStatus())
        }
    }, [settingLeadStatusModificationStatus]);

    const handleCheckboxChange = (value) => {
        setLeadStatus(prevStatuses =>
            prevStatuses.map(d =>
                d.value === value ? { ...d, followups_lead_status_allowed: !d.followups_lead_status_allowed } : d
            )
        );
    };

    const handleFollwupsLeadStatusChange = (value, newFollowupStatus) => {
        setLeadStatus(prevStatuses =>
            prevStatuses.map(status =>
                status.value === value
                    ? { ...status, followups_lead_status: newFollowupStatus }
                    : status
            )
        );
    };

    const handleSelectAllChange = () => {
        const areAllSelected = leadStatus.every(status => status.followups_lead_status_allowed);
        setLeadStatus(prevStatuses =>
            prevStatuses.map(d => ({ ...d, followups_lead_status_allowed: !areAllSelected }))
        );
    };

    const onSaveClicked = async () => {
        // e.preventDefault();
        if (isFormValid(SLS_FORM)) {
            setSLSFSubmitted(false);
            dispatch(updateSettingLeadStatus({
                id: "",
                data: JSON.stringify({
                    settingLeadStatus: leadStatus
                })
            }));
        } else {
            setSLSFSubmitted(true);
        }
    }

    return (
        <>
            {(settingLeadStatusRequestStatus === fetchStatus.LOADING ||
                settingLeadStatusModificationStatus === fetchStatus.LOADING
            ) && <div className="app-container-loader">
                    <ImageLoader />
                </div>}

            <div className="row justify-content-between mb-4">
                <div className="col-md-6">
                    <span className="detail-container-title">Lead Status</span>
                </div>
                <div className="col-auto">
                    <button
                        className="btn btn-primary"
                        onClick={onSaveClicked}
                    // disabled={selectedUsers.length === 0}
                    >
                        Save
                    </button>
                </div>
            </div>
            <div className="table-responsive vh-100">
                <table className="table table-hover custom-table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onChange={handleSelectAllChange}
                                    checked={leadStatus.every(status => status.followups_lead_status_allowed)}
                                />
                            </th>
                            <th scope="col">Label</th>
                            {/* <th scope="col">Internal Name</th> */}
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {leadStatus.map((d, ind) => {
                            // const isValidOption = leadStatusOptions.includes(d.followups_lead_status);
                            const isValidOption = leadStatusOptionsHelperText.some(option => option.value === d.followups_lead_status);

                            return (<tr key={ind}>
                                <td>
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        onChange={() => handleCheckboxChange(d.value)}
                                        // checked={selectedLeadStatus.includes(d.value)}
                                        checked={d.followups_lead_status_allowed}
                                    />
                                </td>
                                <td>{d.label}</td>
                                {/* <td>{d.value}</td> */}
                                <td>
                                    {/* <Selector
                                        id={`drpPropertyLabel-${d.value}`}
                                        name={`drpPropertyLabel-${d.value}`}
                                        SelectorClasses="form-select"
                                        value={isValidOption ? d.followups_lead_status : ""}
                                        options={
                                            <>
                                                {leadStatusOptions.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </>
                                        }
                                        onChange={(e) => handleFollwupsLeadStatusChange(d.value, e.target.value)}
                                        required={true}
                                        errorMessage="Please select a valid option."
                                        form={SLS_FORM}
                                        submited={sLSFSubmitted}
                                    /> */}

                                    <SelectorHelperText
                                        id={`drpPropertyLabel-${d.value}`}
                                        name={`drpPropertyLabel-${d.value}`}
                                        value={isValidOption ? d.followups_lead_status : ""}
                                        options={_leadStatusOptionsHelperText}
                                        onChange={(e) => handleFollwupsLeadStatusChange(d.value, e.target.value)}
                                        required={true}
                                        errorMessage="Please select a valid option."
                                        form={SLS_FORM}
                                        submited={sLSFSubmitted}
                                    />
                                </td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default SettingLeadStatus

