import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLayout } from '../../../../component/layouts/reducerSlices/layoutSlice';
import { getVocabulary, selectModificationStatus, selectVocabulary, selectVocabularyStatus, updateModificationStatus, updateSingleData, updateVocabulary } from '../../reducerSlices/vocabularySlice';
import { fetchStatus } from '../../../../api/client';
import ImageLoader from '../../../../component/Controls/loaders/imageLoader';


function Vocabulary() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateLayout({
      page: 2,
      title: "Settings"
    }));
  }, []);

  const [properties, setProperties] = useState({});

  const vocabularyRequestStatus = useSelector(selectVocabularyStatus);
  const vocabulary = useSelector(selectVocabulary);
  const vocabularyModificationStatus = useSelector(selectModificationStatus);

  const _loadVocabulary = async () => {
    await dispatch(getVocabulary({}));
  }

  useEffect(() => {
    if (vocabularyRequestStatus === fetchStatus.IDLE) {
      _loadVocabulary();
    }
  }, [vocabularyRequestStatus]);

  useEffect(() => {
    if (vocabularyRequestStatus === fetchStatus.SUCCEEDED) {
      // let itemsVocabulary = vocabulary["itemsVocabulary"];
      // setProperties(itemsVocabulary);
      if (vocabulary != null) {
        let itemsVocabulary = vocabulary["itemsVocabulary"];
        setProperties(itemsVocabulary);
      } else {
        setProperties({});
      }
    }
  }, [vocabularyRequestStatus]);

  useEffect(() => {
    if (vocabularyModificationStatus === fetchStatus.SUCCEEDED) {
      dispatch(updateSingleData({ itemsVocabulary: properties }))
      dispatch(updateModificationStatus())
    }
  }, [vocabularyModificationStatus])

  const onSaveClicked = (e, id) => {
    e.preventDefault();

    let _vocabulary = JSON.parse(JSON.stringify(vocabulary));
    _vocabulary["itemsVocabulary"] = properties;

    dispatch(updateVocabulary({
      id: id,
      data: JSON.stringify({
        vocabulary: JSON.stringify(_vocabulary)
      })
    }));
  }

  const handlePropertyChange = (section, key, field, value) => {
    setProperties((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [key]: {
          ...prevState[section][key],
          [field]: value
        }
      }
    }));
  };

  return (
    <>
      {(vocabularyRequestStatus === fetchStatus.LOADING ||
        vocabularyModificationStatus === fetchStatus.LOADING
      ) && <div className="app-container-loader">
          <ImageLoader />
        </div>}

      <div className="row justify-content-between mb-4">
        <div className="col-md-6">
          <span className="detail-container-title">Vocabulary</span>
        </div>
        <div className="col-auto">
          <button
            className="btn btn-primary"
            onClick={(e) => onSaveClicked(e, vocabulary ? vocabulary.id : "")}
            disabled={!vocabulary || !vocabulary.id}
          >Save
          </button>
        </div>
      </div>


      {/* {Object.entries(properties).map(([section, items]) =>
        Object.entries(items).map(([key, property]) => (
          <tr key={key} className="align-middle">
            <td>{property.internalName}</td>
            <td>
              <input
                type="text"
                className="form-control"
                value={property.propertyLabel}
                onChange={(e) =>
                  handlePropertyChange(section, key, "propertyLabel", e.target.value)
                }
              />
            </td>
            <td>
              <select
                className="form-select"
                value={property.fieldType}
                onChange={(e) =>
                  handlePropertyChange(section, key, "fieldType", e.target.value)
                }
              >
                <option value="text">Text</option>
                <option value="boolean">Boolean</option>
              </select>
            </td>
          </tr>
        ))
      )} */}


      <div className="accordion accordion-flush m-3" id="accordionVocabulary">
        <div className="table-responsive">
          {Object.entries(properties).map(([section, items], index) => (
            <>
              <div className="accordion-item">
                <h2 className="accordion-header text-center" id={`flush-heading_${index}`}>
                  <button className="accordion-button collapsed accordion-button-skill" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse_${index}`} aria-expanded="false" aria-controls={`flush-collapse_${index}`}>
                    {section.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                  </button>
                </h2>
                <div id={`flush-collapse_${index}`} className="accordion-collapse collapse" aria-labelledby={`flush-heading_${index}`} data-bs-parent="#accordionVocabulary">
                  <div className="accordion-body accordion-body-vocabulary">


                    <div key={section} className="mb-5">
                      {/* Section Header */}
                      {/* <span className="mt-3">{section.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</span> */}
                      {/* <hr /> */}

                      <table className="table table-hover custom-table">
                        <thead className="">
                          <tr>
                            <th>Internal Name</th>
                            <th>Property Label</th>
                            <th>Field Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(items).map(([key, property]) => (
                            <tr key={key} className="align-middle">
                              <td>{property.internalName}</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={property.propertyLabel}
                                  onChange={(e) =>
                                    handlePropertyChange(section, key, "propertyLabel", e.target.value)
                                  }
                                />
                              </td>
                              <td>
                                <select
                                  className="form-select"
                                  value={property.fieldType}
                                  onChange={(e) =>
                                    handlePropertyChange(section, key, "fieldType", e.target.value)
                                  }
                                >
                                  <option value="text">Text</option>
                                  <option value="boolean">Boolean</option>
                                  <option value="number">Number</option>
                                </select>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>
            </>
          ))}
        </div >
      </div >
    </>
  );
}

export default Vocabulary;
