import {
    createSlice,
    createAsyncThunk
}
    from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import Collection from '../../../component/Controls/paginators/collection';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON } from '../../../utilities/utilityFunctions';

const initialState = {
    data: {
        totalItems: 0,
        items: [],
        status: fetchStatus.IDLE,
        error: null,
        currentPage: 0,
        isFiltered: false,
        records: new Collection(),
        firstRecords: new Collection()
    },
};

export const getContacts = createAsyncThunk('contacts/getContacts', async (contactsModel, { rejectWithValue }) => {
    try {
        // const response = await BajoAPI.post('Process/getContacts', userModel, getAxiosRequestConfig());
        // const data = response.data ? response.data : {};
        // let contacts = [];
        // let totalItems = 0;
        // if (data.Data && isJSON(data.Data)) {
        //     const parsedData = JSON.parse(data.Data);
        //     contacts = parsedData.items || [];
        //     totalItems = parsedData.totalItems || 0;
        // }

        // return {
        //     contacts: contacts,
        //     success: data.Success,
        //     totalItems: totalItems,
        // };

        const response = await BajoAPI.post('Process/getContacts', contactsModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.Data : "[]";
        let contacts;
        if (isJSON(data)) {
            contacts = JSON.parse(data);
        }
        return {
            page: contactsModel.page,
            contacts: contacts,
            success: response.data.Success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});


export const contactsSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        updateStatus: (state) => {
            state.data.status = fetchStatus.DONE;
        },
        updateCurrentPage: (state, page) => {
            state.data.currentPage = page.payload;
        },
        updateIsFiltered: (state) => {
            state.data.isFiltered = true;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getContacts.pending, (state, action) => {
            state.data.status = fetchStatus.LOADING;
        }).addCase(getContacts.fulfilled, (state, action) => {
            // state.data.totalItems = action.payload.totalItems;
            // state.data.records = action.payload.contacts;
            // let _records = new Collection();
            // _records.Add(action.payload.page, action.payload.users);

            // state.data.isFiltered = false;
            // state.data.status = fetchStatus.SUCCEEDED;
            // toaster.success(action.payload.success);


            state.data.totalItems = action.payload.contacts.totalItems;
            let _records = new Collection();
            _records.Add(action.payload.page, action.payload.contacts.items);
            if (!state.data.isFiltered) {
                _records.Concat(state.data.records);
            }
            else {
                state.data.firstRecords = new Collection();
                state.data.currentPage = 0;
            }
            state.data.isFiltered = false;
            state.data.records = _records;
            state.data.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getContacts.rejected, (state, action) => {
            state.data.status = fetchStatus.FAILED;
            state.data.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "Failed to load data");
            // toaster.error("noo")
        });
    }
});

export const {
    updateStatus, updateCurrentPage, updateIsFiltered
} = contactsSlice.actions;

export default contactsSlice.reducer

export const selectContacts = state => state.contacts.data.records;
export const selectTotalItems = state => state.contacts.data.totalItems;
export const selectStatus = state => state.contacts.data.status;
export const selectError = state => state.contacts.data.error;
export const selectCurrentPage = state => state.contacts.data.currentPage;