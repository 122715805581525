import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLayout } from '../../../../component/layouts/reducerSlices/layoutSlice';
import { fetchStatus } from '../../../../api/client';
import { getSettingCallDispositionStatus, selectModificationStatus, selectSettingCallDispositionStatus, selectSettingCallDispositionStatusStatus, updateModificationStatus, updateSettingCallDispositionStatus, updateSingleData } from '../../reducerSlices/settingCallDispositionStatusSlice';
import { callDispositionOptions, callDispositionOptionsHelperText } from '../../../../common/constants';
import Selector from '../../../../component/Controls/custom/selectors/selector';
import { isFormValid } from '../../../../component/Controls/services/formService';
import ImageLoader from '../../../../component/Controls/loaders/imageLoader';
import SelectorHelperText from '../../../../component/Controls/custom/selectors/selectorHelperText';


function SettingCallDispositionStatus() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateLayout({
      page: 2,
      title: "Call Disposition Status"
    }));
  }, []);

  const SCDSF_FORM = "SCDSFForm";
  const [sCDSFSubmitted, setSCDSFSubmitted] = useState(false);

  const [properties, setProperties] = useState({});

  const settingCallDispositionStatusRequestStatus = useSelector(selectSettingCallDispositionStatusStatus);
  const settingCallDispositionStatus = useSelector(selectSettingCallDispositionStatus);
  const settingCallDispositionStatusModificationStatus = useSelector(selectModificationStatus);


  const _loadSettingCallDispositionStatus = async () => {
    await dispatch(getSettingCallDispositionStatus({}));
  }

  useEffect(() => {
    if (settingCallDispositionStatusRequestStatus === fetchStatus.IDLE) {
      _loadSettingCallDispositionStatus();
    }
  }, [settingCallDispositionStatusRequestStatus]);

  useEffect(() => {
    if (settingCallDispositionStatusRequestStatus === fetchStatus.SUCCEEDED) {
      let callDispositionStatus = settingCallDispositionStatus["callDispositionStatus"];
      if (callDispositionStatus) {
        setProperties(callDispositionStatus);
      } else {
        setProperties({});
      }
    }
  }, [settingCallDispositionStatusRequestStatus]);

  useEffect(() => {
    if (settingCallDispositionStatusModificationStatus === fetchStatus.SUCCEEDED) {
      dispatch(updateSingleData({ callDispositionStatus: properties }))
      dispatch(updateModificationStatus())
    }
  }, [settingCallDispositionStatusModificationStatus]);

  const onSaveClicked = (e, id) => {
    e.preventDefault();

    if (isFormValid(SCDSF_FORM)) {
      setSCDSFSubmitted(false);
      let _callDispositionStatus = JSON.parse(JSON.stringify(settingCallDispositionStatus));
      _callDispositionStatus["callDispositionStatus"] = properties;
      dispatch(updateSettingCallDispositionStatus({
        id: id,
        data: JSON.stringify({
          callDispositionStatus: JSON.stringify(properties)
        })
      }));

    } else {
      setSCDSFSubmitted(true);
    }

    // const allValuesSet = Object.values(properties).every(
    //   (property) => property.propertyLabel && property.propertyLabel.trim() !== ""
    // );

    // if (!allValuesSet) {
    //   alert("Please make sure all property labels are set.");
    //   return;
    // }


  }

  const handlePropertyLabelChange = (id, newLabel) => {
    setProperties((prevProperties) => ({
      ...prevProperties,
      [id]: {
        ...prevProperties[id],
        propertyLabel: newLabel,
      },
    }));
  };

  const _callDispositionOptionsHelperText = [
    { value: "", helperText: " -- Select -- " },
    ...callDispositionOptionsHelperText
  ];

  return (
    <>
      {(settingCallDispositionStatusRequestStatus === fetchStatus.LOADING ||
        settingCallDispositionStatusModificationStatus === fetchStatus.LOADING
      ) && <div className="app-container-loader">
          <ImageLoader />
        </div>}

      <div className="row justify-content-between mb-4">
        <div className="col-md-6">
          <span className="detail-container-title">Call Disposition Status Options</span>
        </div>
        <div className="col-auto">
          <button
            className="btn btn-primary"
            onClick={(e) => onSaveClicked(e, settingCallDispositionStatus ? settingCallDispositionStatus.id : "")}
            disabled={!settingCallDispositionStatus || !settingCallDispositionStatus.id}
          >
            Save
          </button>
        </div>
      </div>
      <div className="row justify-content-between mb-4">
        <div className="col-md-12">
          <div className="table-responsive vh-100">
            <table className="table table-hover custom-table">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Internal Label</th>
                  {/* <th scope="col">Internal Name</th> */}
                  <th scope="col">Property Label</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(properties).map(([key, value]) => {
                  // const isValidOption = callDispositionOptions.includes(value.propertyLabel);
                  const isValidOption = callDispositionOptionsHelperText.some(option => option.value === value.propertyLabel);
                  return (
                    <tr key={key} className="align-middle">
                      <td>{value.label}</td>
                      {/* <td>{value.internalName}</td> */}
                      <td>
                        {/* <select
                          value={isValidOption ? value.propertyLabel : ""}
                          onChange={(e) => handlePropertyLabelChange(key, e.target.value)}
                          className="form-select"
                        >
                          <option value="" disabled>-- Select --</option>
                          {callDispositionOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select> */}

                        {/* <Selector
                          id={`drpPropertyLabel-${value.label}`}
                          name={`drpPropertyLabel-${value.label}`}
                          SelectorClasses="form-select"
                          value={isValidOption ? value.propertyLabel : ""}
                          options={
                            <>
                              {callDispositionOptions.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </>
                          }
                          onChange={(e) => handlePropertyLabelChange(key, e.target.value)}
                          required={true}
                          errorMessage="Please select a valid option."
                          form={SCDSF_FORM}
                          submited={sCDSFSubmitted} /> */}

                        <SelectorHelperText
                          id={`drpPropertyLabel-HelperText-${value.label}`}
                          name={`drpPropertyLabel-HelperText-${value.label}`}
                          value={isValidOption ? value.propertyLabel : ""}
                          options={_callDispositionOptionsHelperText}
                          onChange={(e) => handlePropertyLabelChange(key, e.target.value)}
                          required={true}
                          errorMessage="Please select a valid option."
                          form={SCDSF_FORM}
                          submited={sCDSFSubmitted} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default SettingCallDispositionStatus