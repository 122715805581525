import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLayout } from '../../../../component/layouts/reducerSlices/layoutSlice';
import { fetchStatus } from '../../../../api/client';
import Statement from '../../../../component/Controls/custom/textbox/statement';
import { isFormValid } from '../../../../component/Controls/services/formService';
import { getSettingSequenceAgain, selectModificationStatus, selectSettingSequenceAgain, selectSettingSequenceAgainStatus, updateModificationStatus, updateSettingSequenceAgain, updateSingleData } from '../../reducerSlices/settingSequenceAgainSlice';
import NumberText from '../../../../component/Controls/custom/textbox/numberText';
import ImageLoader from '../../../../component/Controls/loaders/imageLoader';


function SettingSequenceAgain() {

    const dispatch = useDispatch();
    const SequenceAgain_FORM = "SequenceAgainForm";

    useEffect(() => {
        dispatch(updateLayout({
            page: 2,
            title: "Sequence Again"
        }));
    }, []);

    const [sequenceAgainFSubmitted, setSequenceAgainFSubmitted] = useState(false);
    const [sequenceAgainDays, setSequenceAgainDays] = useState("");

    const settingSequenceAgainRequestStatus = useSelector(selectSettingSequenceAgainStatus);
    const settingSequenceAgain = useSelector(selectSettingSequenceAgain);
    const settingSequenceAgainModificationStatus = useSelector(selectModificationStatus);

    const _loadSettingSequenceAgain = async () => {
        await dispatch(getSettingSequenceAgain({}));
    }

    useEffect(() => {
        if (settingSequenceAgainRequestStatus === fetchStatus.IDLE) {
            _loadSettingSequenceAgain();
        }
    }, [settingSequenceAgainRequestStatus]);

    useEffect(() => {
        setSequenceAgainDays(settingSequenceAgain && settingSequenceAgain.sequenceAgainDays)
    }, [settingSequenceAgain])

    useEffect(() => {
        if (settingSequenceAgainModificationStatus === fetchStatus.SUCCEEDED) {
            dispatch(updateSingleData({ sequenceAgainDays: sequenceAgainDays }))
            dispatch(updateModificationStatus())
        }
    }, [settingSequenceAgainModificationStatus])

    const onSaveClicked = async (e, id) => {
        e.preventDefault();

        if (isFormValid(SequenceAgain_FORM)) {
            setSequenceAgainFSubmitted(false);

            dispatch(updateSettingSequenceAgain({
                id: id,
                data: JSON.stringify({
                    sequenceAgainDays: sequenceAgainDays
                })
            }));
        } else {
            setSequenceAgainFSubmitted(true);
        }
    }

    return (
        <>
            {(settingSequenceAgainRequestStatus === fetchStatus.LOADING ||
                settingSequenceAgainModificationStatus === fetchStatus.LOADING
            ) && <div className="app-container-loader">
                    <ImageLoader />
                </div>}

            <div className="row justify-content-between mb-4">
                <div className="col-md-10">
                    <span className="detail-container-title">Sequence Again </span>
                    <br />
                    <span className="detail-container-sub-title">There is a specified amount of time passed since the last outreach attempt made in the previous sequence</span>
                </div>
                <div className="col-auto">
                    <button
                        className="btn btn-primary"
                        onClick={(e) => onSaveClicked(e, settingSequenceAgain ? settingSequenceAgain.id : "")}
                    >
                        Save
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col-4">
                    <label id="lblSequenceAgain" className="field-label pt-2" htmlFor="txtSequenceAgain">Sequence Again Days</label>
                    <NumberText
                        id="txtSequenceAgainDays"
                        name="txtSequenceAgainDays"
                        NumberTextClasses="form-control"
                        max="999"
                        maxLength={3}
                        required={true}
                        errorMessage="Please enter sequence again days"
                        form={SequenceAgain_FORM}
                        submited={sequenceAgainFSubmitted}
                        value={sequenceAgainDays}
                        onChange={(e) => setSequenceAgainDays(e.target.value)}
                        placeholder="Enter Sequence Again Days"
                    />
                </div>
            </div>
        </>
    )
}

export default SettingSequenceAgain