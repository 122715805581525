import React, { useEffect, useState } from 'react';
import { Link, useNavigate as useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addCurrentStep, getProfile, selectModificationProfileStatus, selectProfile, selectProfileStatus, updateLoginStatus, updateProfile } from '../reducerSlices/accountsSlice';
import { fetchStatus, redirectHubSpotMarketPlace } from '../../../api/client';
import { accountService } from '../services/accountService';
import { isFormValid } from '../../../component/Controls/services/formService';
// import { handlerError } from '../../../common/common';
import InputGroup from '../../../component/Controls/custom/inputGroup/inputGroup';
import ShortText from '../../../component/Controls/custom/textbox/shortText';
import PhoneText from '../../../component/Controls/custom/textbox/phoneText';
import EmailText from '../../../component/Controls/custom/textbox/emailText';
import PasswordText from '../../../component/Controls/custom/textbox/passwordText';
import { registerDetailsEN } from '../services/registerDetails';
import RegisterButton from '../../../component/Controls/custom/buttons/registerButton';
import DateText from '../../../component/Controls/custom/textbox/dateText';
import Selector from '../../../component/Controls/custom/selectors/selector';
import Logo from '../../../component/logo/logo';
import registerPageImg from '../../../assets/images/register-page.png';
import EllipsisLoader from '../../../component/Controls/loaders/ellipsisLoader';
import ContinueButton from '../../../component/Controls/buttons/continueButton';
import Progress from '../../../component/Progress/Progress';
import ImageLoader from '../../../component/Controls/loaders/imageLoader';


const ProfileDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const PROFILE_FORM = "ProfileForm";

  const profileStatus = useSelector(selectProfileStatus);
  const profile = useSelector(selectProfile);
  const modificationProfileStatus = useSelector(selectModificationProfileStatus);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [numberOfEmployees, setNumberOfEmployees] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [rfSubmitted, setRFSubmitted] = useState(false);

  const registerDetail = registerDetailsEN;

  dispatch(addCurrentStep('Account'));

  const _loadProfile = async () => {
    await dispatch(getProfile({
    }));
  }

  useEffect(() => {
    if (profileStatus === fetchStatus.IDLE) {
      if (accountService.isAuthenticated()) {
        _loadProfile();
      }
    }
  }, [profileStatus]);

  useEffect(() => {
    if (profileStatus === fetchStatus.SUCCEEDED) {
      if (profile) {
        setFirstName(profile["FirstName"]);
        setLastName(profile["LastName"]);
        setPhoneNumber(profile["PhoneNumber"]);
        setEmail(profile["Email"]);
        // setDateOfBirth(profile["DateOfBirth"]);
        setCompanyName(profile["CompanyName"]);
        // setNumberOfEmployees(profile["NumberOfEmployees"]);
      }
    }
  }, [profileStatus]);


  const onProfileSaveClicked = (e) => {
    e.preventDefault();
    if (isFormValid(PROFILE_FORM)) {
      dispatch(updateProfile({
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        // dateOfBirth: dateOfBirth,
        companyName: companyName,
        // numberOfEmployees: numberOfEmployees
      }));
    }
    else {
      setRFSubmitted(true);
    }
  }

  useEffect(() => {
    if (modificationProfileStatus === fetchStatus.SUCCEEDED) {
      if (accountService.isAuthenticated()) {
        redirectHubSpotMarketPlace();
      }
    }
  }, [modificationProfileStatus]);


  return (
    <>
      {(profileStatus === fetchStatus.LOADING
      ) && <div className="app-container-loader">
          <ImageLoader />
        </div>
      }

      {/* <div className="row justify-content-center align-items-center vh-100 m-0">
        <div className="col-xl-12 col-xxl-8">
          <div className="container justify-content-center ">
            <div className="animate__animated animate__fadeIn">
              <div className="card card-wizard overflow-hidden" >
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-md-4 pe-0 d-none d-md-flex" style={{ backgroundColor: '#e5cdf3' }}>
                      <img src={registerPageImg} className="img-responsive" />
                    </div>
                    <div className="col-md-8 py-3">
                      <div className="row">
                        <div className="col-md-6 text-start mt-3 mb-4">
                          <h3>Quick Setup</h3>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-4">
                            <label className=' mb-0'>{registerDetail.FirstName}</label>
                            <ShortText
                              id="txtFirstName"
                              name="txtFirstName"
                              ShortTextClasses="form-control"
                              placeholder={registerDetail.FirstName}
                              required={true}
                              errorMessage={registerDetail.FirstNameError}
                              form={PROFILE_FORM}
                              submited={rfSubmitted}
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-4">
                            <label>{registerDetail.LastName}</label>
                            <InputGroup
                              icon={<i className="fa fa-address-card"></i>}
                              control={
                                <ShortText
                                  id="txtLastName"
                                  name="txtLastName"
                                  ShortTextClasses="form-control"
                                  placeholder={registerDetail.LastName}
                                  required={true}
                                  errorMessage={registerDetail.LastNameError}
                                  form={PROFILE_FORM}
                                  submited={rfSubmitted}
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)} />
                              } />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-4">
                            <label>{registerDetail.PhoneNumber}</label>
                            <InputGroup
                              icon={<i className="fa fa-phone"></i>}
                              control={
                                <PhoneText
                                  id="txtPhoneNumber"
                                  name="txtPhoneNumber"
                                  PhoneTextClasses="form-control"
                                  placeholder={registerDetail.PhoneNumber}
                                  required={true}
                                  errorMessage={registerDetail.PhoneNumberError}
                                  form={PROFILE_FORM}
                                  submited={rfSubmitted}
                                  value={phoneNumber}
                                  onChange={(e) => setPhoneNumber(e.target.value)} />
                              } />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-4">
                            <label>{registerDetail.Email}</label>
                            <InputGroup
                              icon={<i className="fa fa-envelope"></i>}
                              control={
                                <EmailText
                                  id="txtEmail"
                                  name="txtEmail"
                                  EmailTextClasses="form-control"
                                  placeholder={registerDetail.Email}
                                  required={true}
                                  errorMessage={registerDetail.EmailError}
                                  form={PROFILE_FORM}
                                  submited={rfSubmitted}
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  disabled={true} />
                              } />
                          </div>
                        </div>
                      </div>
                      <div className="row">

                        <div className="col-md-6">
                          <div className="form-group mb-4">
                            <label>{registerDetail.CompanyName}</label>
                            <InputGroup
                              icon={<i className="fa fa-address-card"></i>}
                              control={
                                <ShortText
                                  id="txtCompanyName"
                                  name="txtCompanyName"
                                  ShortTextClasses="form-control"
                                  placeholder={registerDetail.CompanyName}
                                  required={true}
                                  errorMessage={registerDetail.CompanyNameError}
                                  form={PROFILE_FORM}
                                  submited={rfSubmitted}
                                  value={companyName}
                                  onChange={(e) => setCompanyName(e.target.value)} />
                              } />
                          </div>
                        </div>
                      </div>

                      <div className="row pt-3">
                        <div className="col-auto mb-4">
                          <RegisterButton
                            text="Save Details"
                            title="Save Profile Details"
                            disabled={profileStatus === fetchStatus.LOADING || modificationProfileStatus === fetchStatus.LOADING}
                            loading={modificationProfileStatus === fetchStatus.LOADING}
                            onRegisterClicked={(e) => onProfileSaveClicked(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container wizard-container">
        <div className="row">
          <div className="col-md-8 card card-left d-flex align-items-stretch p-0">
            <div className="card-header">
              <div className="row p-2">
                <div className="col-md-12">
                  <div className="card-left-header">Account Info</div>
                  <div className="card-left-sub-header">Please enter your details</div>
                </div>
              </div>
            </div>
            <div className="card-body mb-5 mb-md-0 animate__animated animate__fadeIn">
              {/* <div className="row p-2">
                <div className="col-md-12">
                  <div className="card-left-header">Profile Info</div>
                  <div className="card-left-sub-header">Please enter your details</div>
                </div>
              </div> */}
              <div className="row p-2">
                <div className="col-md-6">
                  <div className="form-group mb-0">
                    <label className='field-label mb-0'>{registerDetail.FirstName}</label>
                    <ShortText
                      id="txtFirstName"
                      name="txtFirstName"
                      ShortTextClasses="form-control"
                      placeholder={registerDetail.FirstName}
                      required={true}
                      errorMessage={registerDetail.FirstNameError}
                      form={PROFILE_FORM}
                      submited={rfSubmitted}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-0">
                    <label className='field-label'>{registerDetail.LastName}</label>
                    <InputGroup
                      icon={<i className="fa fa-address-card"></i>}
                      control={
                        <ShortText
                          id="txtLastName"
                          name="txtLastName"
                          ShortTextClasses="form-control"
                          placeholder={registerDetail.LastName}
                          required={true}
                          errorMessage={registerDetail.LastNameError}
                          form={PROFILE_FORM}
                          submited={rfSubmitted}
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)} />
                      } />
                  </div>
                </div>
              </div>
              <div className="row p-2">
                <div className="col-md-6">
                  <div className="form-group mb-0">
                    <label className='field-label'>{registerDetail.PhoneNumber}</label>
                    <InputGroup
                      icon={<i className="fa fa-phone"></i>}
                      control={
                        <PhoneText
                          id="txtPhoneNumber"
                          name="txtPhoneNumber"
                          PhoneTextClasses="form-control"
                          placeholder={registerDetail.PhoneNumber}
                          required={true}
                          errorMessage={registerDetail.PhoneNumberError}
                          form={PROFILE_FORM}
                          submited={rfSubmitted}
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)} />
                      } />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-0">
                    <label className='field-label'>{registerDetail.Email}</label>
                    <InputGroup
                      icon={<i className="fa fa-envelope"></i>}
                      control={
                        <EmailText
                          id="txtEmail"
                          name="txtEmail"
                          EmailTextClasses="form-control"
                          placeholder={registerDetail.Email}
                          required={true}
                          errorMessage={registerDetail.EmailError}
                          form={PROFILE_FORM}
                          submited={rfSubmitted}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          disabled={true} />
                      } />
                  </div>
                </div>
              </div>
              <div className="row p-2">
                <div className="col-md-6">
                  <div className="form-group mb-0">
                    <label className='field-label'>{registerDetail.CompanyName}</label>
                    <InputGroup
                      icon={<i className="fa fa-address-card"></i>}
                      control={
                        <ShortText
                          id="txtCompanyName"
                          name="txtCompanyName"
                          ShortTextClasses="form-control"
                          placeholder={registerDetail.CompanyName}
                          required={true}
                          errorMessage={registerDetail.CompanyNameError}
                          form={PROFILE_FORM}
                          submited={rfSubmitted}
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)} />
                      } />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer bottom-0 px-4 py-2 w-100">
              <div className="row">
                <div className="col-md-12">
                  {
                    (profileStatus === fetchStatus.LOADING ||
                      modificationProfileStatus === fetchStatus.LOADING
                    ) ? <div className="float-end my-2"><EllipsisLoader /></div>
                      :
                      <>
                        {/* <BackButton onBackClicked={onBack} /> */}
                        <div className="float-end">
                          <ContinueButton
                            disabled={profileStatus === fetchStatus.LOADING || modificationProfileStatus === fetchStatus.LOADING}
                            onClick={(e) => onProfileSaveClicked(e)}
                          />
                        </div>
                      </>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 card card-right d-flex align-items-stretch p-0">
            <Progress percentageStart="0" percentage="10" getPercentage="15" subTitle="Account Info" currentPage="personalInfo1" />
          </div>
        </div>
      </div>
    </>
  )
};

export default ProfileDetails; 