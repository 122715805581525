import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, selectModalPopUpDetail } from './reducerSlices/congratulationsModalPopUpSlice';
import meteorRainImg from './../../../assets/images/clipart/meteor-rain.gif';
import circleCheckImg from './../../../assets/images/clipart/circle-check.gif';


function CongratulationsModalPopUp(props) {

    const dispatch = useDispatch();
    const modalPopUpDetails = useSelector(selectModalPopUpDetail);

    const onCloseClicked = () => {
        dispatch(closeModal());
    };

    return (
        <>
            {(modalPopUpDetails && modalPopUpDetails.show) ? (<>
                <div className="modal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md mb-0" role="document">
                        <div className="modal-content">
                            {/* <div className="modal-header d-block">
                                <div className="d-flex">
                                    <h5 className="modal-title">Schedule a meeting</h5>
                                    <button
                                        type="button"
                                        className="modal-close-button ms-auto"
                                        onClick={onCloseClicked}>
                                        <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                                    </button>
                                </div>

                            </div> */}
                            <div className="modal-body">
                                {/* <div className="row ">
                                    <button
                                        type="button"
                                        className="modal-close-button ms-auto"
                                        onClick={onCloseClicked}>
                                        <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                                    </button>
                                </div> */}
                                <div className="row my-4">
                                    <div className="col-md-12 justify-content-center px-5">
                                        <center>
                                            {/* <img src={meteorRainImg} alt="no photo" style={{ width: "50%" }} /> */}
                                            {/* <img src={circleCheckImg} alt="no photo"  /> */}
                                            <i class="fa fa-check-circle text-success fa-5x" aria-hidden="true"></i>

                                            <div className="animate__animated animate__zoomIn">
                                                <h3 className="pt-3">
                                                    Congratulations!</h3>
                                            </div>
                                            <button className="btn btn-primary w-auto px-4 mt-3" onClick={onCloseClicked}>Continue</button>
                                        </center>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-backdrop fade show">
                </div>
            </>) : <></>}
        </>
    );
}

export default CongratulationsModalPopUp;