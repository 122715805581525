import React, { useState } from "react";
import { followupsStatusesData } from "../../../common/constants";


const ContactCard = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const onClickedExpanded = () => {
    setIsExpanded(!isExpanded)
  }

  const _getStatusClass = () => {
    if (props.followups === followupsStatusesData.Enrich) {
      return 'status-enrich';
    }
    else if (props.followups === followupsStatusesData.Nurture) {
      return 'status-nurture';
    }
    else if (props.followups === followupsStatusesData.Disqualified) {
      return 'status-disqualified';
    }
    else if (props.followups === followupsStatusesData.Bogus) {
      return 'status-bogu';
    }
    else if (props.followups === followupsStatusesData.Prospect) {
      return 'status-prospect';
    }
    else if (props.followups === followupsStatusesData.ChangeCommunicationChannel) {
      return 'status-change-communication';
    }
    else if (props.followups === followupsStatusesData['1:1SalesApproach']) {
      return 'status-sales-approach';
    }
  }


  return (
    <>
      <div className="contact-card" key={props.id}>
        <div className="contact-card-body cursor-pointer" onClick={onClickedExpanded}>
          <div className={`status ${_getStatusClass()}`}>{props.followups}</div>
          <div className="row w-100 m-0 w-100">
            <div className="contact-card-user-row">
              <div className="contact-card-photo">
                <div className="contact-card-photo-box">
                  <div className='contact-card-photo-box-text'>{props.lastname && props.lastname.slice(0, 1)}{props.firstname && props.firstname.slice(0, 1)}</div>
                </div>
              </div>
              <div className="d-flex flex-column w-100">
                <div className="contact-card-user-name" ><span onClick={props.onClick ? props.onClick : ''} title="View Contact Details on HubSpot">{props.lastname} {props.firstname}</span></div>

                <div className="row contact-card-user-location m-0 p-0 ps-2">
                  <div className="col-md-6">
                    <i className="fa-solid fa-phone pe-2" data-tip="Phone" title="Phone"></i>
                    {props.phone}
                  </div>
                  <div className="col-md-6">
                    <i className="fa-solid fa-industry pe-2" data-tip="Company" title="Company"></i>
                    {props.company}
                  </div>
                  <div className="col-md-6">
                    <i className="fa-solid fa-envelope pe-2" data-tip="Email" title="Email"></i>
                    {props.email}
                  </div>
                  <div className="col-md-6">
                    <i className="fa-solid fa-briefcase pe-2" data-tip="Fits ICP" title="Fits ICP"></i>
                    Fits ICP:
                    {props.followups_fits_icp ?
                      <span className={`contact-card-badge ${props.followups_fits_icp.toLowerCase() === "true" ? "contact-card-badge-success" : "contact-card-badge-danger"}`}>
                        {props.followups_fits_icp.toLowerCase() === "true" ? "Yes" : "No"}
                      </span>
                      : <></>}
                  </div>

                  {/* 
                  <div className="contact-card-user-location">
                  <div className="ps-0">
                    <i className="fa-solid fa-phone pe-2" data-tip="Phone" title="Phone"></i>
                    {props.phone}
                  </div>
                  <div className="ps-4">
                    <i className="fa-solid fa-envelope pe-2" data-tip="Email" title="Email"></i>
                    {props.email}
                  </div>
                  <div className="ps-4">
                    <i className="fa-solid fa-industry pe-2" data-tip="Company" title="Company"></i>
                    {props.company}
                  </div>
                  <div className="ps-4">
                    <i className="fa-solid fa-briefcase pe-2" data-tip="Fits ICP" title="Fits ICP"></i>
                    Fits ICP:
                    {props.followups_fits_icp ?
                      <span className={`contact-card-badge ${props.followups_fits_icp.toLowerCase() === "true" ? "contact-card-badge-success" : "contact-card-badge-danger"}`}>
                        {props.followups_fits_icp.toLowerCase() === "true" ? "Yes" : "No"}
                      </span>
                      : <></>}
                  </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row w-100 m-0 mt-2 contact-card-expandable-btn"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <div className="col-12 text-center">
              {isExpanded ? "Hide Details ▲" : "Show More ▼"}
            </div>
          </div> */}
          {/* <div style={{  padding: "18px" }}></div> */}

          <div className={`contact-card-expandable-content mt-0 w-100 ${isExpanded ? "expanded" : ""}`}>
            <div className="row w-100 m-0 mt-2">
              <div className="contact-card-user-info-col-250 col-12">
                <div className="contact-card-user-info">
                  <div className="d-inline-flex align-items-center">
                    <div>
                      <i className="fa-solid fa-industry fa-xl pe-1" data-tip="ICP Reason" title="ICP Reason"></i>
                    </div>
                    <div className="d-flex flex-column ps-3">
                      <h5>ICP Reason</h5>
                      <small> {props.followups_icp_reason}</small>
                    </div>
                  </div>
                </div>
                <div className="contact-card-user-info">
                  <div className="d-inline-flex align-items-center">
                    <div>
                      <i className="fa-solid fa-comment-dots fa-xl pe-1" data-tip="Followups Reason" title="Followups Reason"></i>
                    </div>
                    <div className="d-flex flex-column ps-3">
                      <h5>Followups Reason</h5>
                      <small>{props.followups_reason}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default ContactCard;
